<template>
  <div class="dolu">
    <projects :rapp="rapp"></projects>
    <exams v-if="id_project > 0"></exams>

    <div v-if="application === 'fastrasxxx'">
      <vars-fg v-if="id_examination > 0 || selected_exams.length > 0"></vars-fg>
    </div>
    <div v-else-if="rapp === 'genetify'">
      <span />
    </div>
    <div v-else>
      <vars v-if="id_examination > 0 || selected_exams.length > 0"></vars>
    </div>
  </div>
</template>

<script>
  import Projects from 'src/components/Views/Projects.vue';
  import Exams from 'src/components/Views/Exams.vue';
  import Vars from 'src/components/Views/Vars.vue';

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';

  export default {
    name: 'projectsInternal',
    props: {
      rapp: String
    },


    components: {
      [Projects.name]: Projects,
      [Exams.name]: Exams,
      [Vars.name]: Vars,
    },


    computed: {
      ...mapState({
        id_project: state => state.id_project,
        id_examination: state => state.id_examination,
        selected_exams: state => state.selected_exams,

        token: state => state.token,
        application: state => state.application,
        app: state => state.app,

      }),

    },


    data () {
      return {
        project_name: "",

      }
    },


    methods: {
      ...mapMutations({

      }),


      ...mapActions({

        }),


    },

    beforeMount () {

    },

  }
</script>

<style lang="scss">

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}

</style>
