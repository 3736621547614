<template>
  <div class="wrapper">
    <div class="ml-auto mr-auto">
      <form @submit.prevent="a_reg({degrees_1:form_reg.degrees_1, firstName:form_reg.firstName, lastName:form_reg.lastName, degrees_2:form_reg.degrees_2, email:form_reg.email, phone:form_reg.phone, password:form_reg.password, verify:form_reg.verify, acceptTerms:form_reg.acceptTerms})">
        <card type="signup" class="text-center">
          <template slot="header">
            <h3 class="card-title"> Registration </h3>
          </template>

          <div class="row">
            <div class="col-6">
              <fg-input v-model="form_reg.degrees_1" addon-left-icon="fas fa-user-graduate" placeholder="Title before name"></fg-input>
            </div>
            <div class="col-6"></div>
          </div>  

          <div class="row">
            <div class="col-6">
              <fg-input v-model="form_reg.firstName" addon-left-icon="fas fa-signature" placeholder="Name"></fg-input>
            </div>
            <div class="col-6">
              <fg-input v-model="form_reg.lastName" addon-left-icon="fas fa-signature" placeholder="Surname"></fg-input>
            </div>
          </div>  

          <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6">
              <fg-input v-model="form_reg.degrees_2" addon-left-icon="fas fa-user-graduate" placeholder="Title after name"></fg-input>
            </div>
            <div class="col-6"></div>
          </div>

          <div class="row">
            <div class="col-6">
              <fg-input v-model="form_reg.password" addon-left-icon="fas fa-fingerprint" placeholder="Password" type="password"></fg-input> 
            </div>
            <div class="col-6">
              <fg-input v-model="form_reg.verify" addon-left-icon="fas fa-fingerprint" placeholder="Verify password" type="password"></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <fg-input v-model="form_reg.email" addon-left-icon="far fa-envelope" placeholder="E-mail"></fg-input>
            </div>
            <div class="col-6">
              <fg-input v-model="form_reg.phone" addon-left-icon="fas fa-mobile-alt" placeholder="Phone"></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
            </div>
          </div>
                            
          <p-checkbox class="text-left" v-model="form_reg.acceptTerms">
            Confirm your acceptance of 
            <a href="/terms_conditions" target="_blank">the terms and conditions</a>, please.
          </p-checkbox>

          <div class="row">
            <p-button native-type="submit" slot="footer" type="success" block class="col-6 mb-3 ml-auto mr-auto"> Register </p-button>
          </div>                
        </card>
      </form>
    </div>

  </div>
</template>
<script>
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';

  export default {
    components: {
      Card,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    data(){
      return {
        form_reg: {
          degrees_1: "",
          firstName: "",
          lastName: "",
          degrees_2: "",
          password: "",
          verify: "",
          email: "",
          phone: "",
          acceptTerms: true,
        },        
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
.full-page > .content {
    padding-top: 8vh !important;
}
</style>
