<template>
  <div class="row">
    <div class="col-md-6">
      <card>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
    <div class="col-md-6">
      <card>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
    <div class="col-md-12">
      <div class="card">
      </div>  <!-- end card -->
    </div> <!-- end col-md-12 -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'

  export default {
    components: {
      Card
    },
    data() {
      return {      }
    }
  }
</script>
<style>
</style>
