export default [{
        name: 'Dashboard',
        icon: 'fas fa-chart-line',
        path: '/admin/overview'
    },
    {
        name: 'Projects',
        icon: 'fas fa-tasks',
        children: [{
                name: 'All Project',
                path: '/projects/internal'

            },
            /*             {
                            name: 'External',
                            path: '/projects/external'
                        }, */
        ]
    },
    {
        name: 'Patients',
        icon: 'fas fa-user-friends',
        children: [{
                name: 'All Patients',
                path: '/patients/patientsall'
            },
            {
                name: 'Statim',
                path: '/patients/statim'
            },
            {
                name: 'Search by Variant',
                path: '/patients/byvariant'
            },
        ]
    },
    // {
    //     name: 'Analyses',
    //     icon: 'far fa-chart-bar',
    //     children: [{
    //             name: 'Workflows',
    //             path: '/analyses/anworkflows'
    //         },
    //         {
    //             name: 'Genes',
    //             path: '/analyses/angenes'
    //         },
    //     ]
    // },
    {
        name: 'Variants',
        icon: 'fas fa-dna',
        children: [{
            name: 'Global Database',
            path: '/variants/variantsall'
        }, ]
    },
    // {
    //     name: 'Filters',
    //     icon: 'fas fa-star',
    //     children: [{
    //             name: 'Create New',
    //             path: '/favorites/blankpage'
    //         },
    //         {
    //             name: 'Show All',
    //             path: '/favorites/next'
    //         },
    //     ]
    // },
    // {
    //     name: 'Reports',
    //     icon: 'fas fa-paper-plane',
    //     children: [{
    //             name: 'Create Template',
    //             path: '/reports/blankpage'
    //         },
    //         {
    //             name: 'Show all',
    //             path: '/reports/next'
    //         },
    //     ]
    // },
    // {
    //     name: 'Chat',
    //     icon: 'far fa-comments',
    //     children: [{
    //         name: 'Start Chat',
    //         path: '/chat/startchat'
    //     }, ]
    // },

    // {
    //     name: 'Settings',
    //     icon: 'fas fa-cogs',
    //     collapsed: true,
    //     children: [{
    //             name: 'Accounts',
    //             path: '/settings/accounts'
    //         },
    //         {
    //             name: 'Workflows',
    //             path: '/settings/workflows'
    //         },

    //     ]
    // },
]