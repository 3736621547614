<template>
  <footer v-if="application === 'GENOVESA'" class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.bioxsys.eu/about_us">
              About Us
            </a>
          </li>
          <li>
            <a href="https://www.bioxsys.eu/contacts">
              Contacts
            </a>
          </li>
          <li>
            <a href="https://www.bioxsys.eu/news">
              News
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; BIOXSYS s.r.o.; 2020
        <!-- <i class="fa fa-heart heart"></i> by
        <a href="https://binarcode.com" target="_blank" rel="noopener">BinarCode</a>.
        <span>Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" rel="noopener" target="_blank">Creative Tim</a>.</span> -->
      </div>
    </div>
  </footer>

  <footer v-else class="in footer_bg">
    <el-row type="flex" class="row-bg" justify="center" :gutter="20">
      <el-col :span="5">
        <div class="grid-content">
          <a href="https://www.biovendor.group?utm_source=webgenovesa&amp;utm_medium=web-footer-logo" target="blank" class="logo" title="We bring to the market diagnostic tools for innovative methods of molecular diagnostics from BioVendor Group laboratories - NGS, LAMP and miRNA.">
            <img src="/img/bv/Mdx_logo_s_adresou.svg" alt="BioVendor Group ENG" />
          </a>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
            <strong>BioVendor MDx a.s.</strong><br />
            info@biovendor-mdx.com <br />
            www.biovendor.cz
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <a href="https://www.viennalab.com/?utm_source=webgenovesa&amp;utm_medium=web-footer-logo" target="blank" class="logo" title="e are an Austrian developer and manufacturer of user-friendly IVD products for human genetic testing.">
            <img src="/img/bv/VL_logo_s_adresou.svg" alt="ViennaLab ENG" />
          </a>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content text">
            <strong>ViennaLab Diagnostics GmbH</strong><br />
            info@viennalab.com <br />
            www.viennalab.com
        </div>
      </el-col>
    </el-row>

    <el-divider content-position="right"> BRIDGING SCIENCE AND DIAGNOSTICS </el-divider>

    <div v-if="!(token > '')" class="webs okraje">
        <strong>We are a member of the group</strong>

        <a href="https://www.biovendor.group?utm_source=webgenovesa&amp;utm_medium=web-footer-logo" target="blank"
            class="logo" title="BioVendor Group – Bridging Science &amp; Diagnostics">
            <img src="/img/bv/BG_logo.svg" alt="BioVendor Group ENG" /></a>
        <div class="text">
            <strong>Bridging Science and Diagnostics</strong><br />
            We believe that every treatment begins with a reliable diagnosis. That’s why we’re looking for innovative
            ways to provide reliable laboratory results to doctors and patients around the world. We are an
            international group of biotechnology companies focusing on the development, production and distribution of
            IVD products and technologies for laboratory automation.
        </div>

        <strong>Our brands</strong>
        <ul class="webs count5">
            <li
                title="We supply diagnostic solutions for a wide range of IVD areas. We bring innovative products, excellent technical support and professional consulting.">
                <a href="https://www.biovendor.cz?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/LM_logo.svg" alt="BioVendor-LM" /></a>
            </li>
            <li
                title="We cover the development of innovative biomarkers and technologies - miRNA, NGS, LAMP, CLIA and immunodiagnostic automation.">
                <a href="https://www.biovendor.com?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/RnD_logo.svg" alt="BioVendor-RD" /></a>
            </li>
            <li
                title="We are a traditional Czech manufacturer and distributor of IVD products focusing on infectious diseases and immunology.">
                <a href="https://www.testlinecd.com?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/TL_logo.svg" alt="TestLine" /></a>
            </li>
            <li
                title="We are an Austrian developer and manufacturer of user-friendly IVD products for human genetic testing.">
                <a href="https://www.viennalab.com/?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/VL_logo_pata.svg" alt="ViennaLab" /></a>
            </li>
            <li
                title="We are an international diagnostic company operating in the field of endocrinology, autoimmune and infectious diseases.">
                <a href="https://www.diasource-diagnostics.com?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/DS_logo.svg" alt="DiaSource" /></a>
            </li>
            <li
                title="We bring to the market diagnostic tools for innovative methods of molecular diagnostics from BioVendor Group laboratories - NGS, LAMP and miRNA.">
                <a href="https://www.biovendor.group?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/Mdx_logo_pata.svg" alt="BioVendor MDx" /></a>
            </li>
        </ul>

        <strong>Joint projects</strong>
        <ul class="webs count5">
            <li title="Fully automated CLIA solution.">
                <a href="https://www.clia.biovendor.group/?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/CLIA_logo.svg" alt="CLIA" /></a>
            </li>
            <li title="A new generation of a unique immunoblot test for effective multiplex diagnostics.">
                <a href="https://www.testlinecd.com/microblot-array-mba?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/MBA_logo.svg" alt="MBA" /></a>
            </li>
            <li title="Fast, efficient and affordable sequencing – from nucleic acids to entire genomes.">
                <a href="https://www.biovendor.group/next-generation-sequencing-ngs?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/NGS_logo.svg" alt="NGS" /></a>
            </li>
            <li title="Innovative methods for the quantification of new-generation biomarkers.">
                <a href="https://www.biovendor.com/microrna--sncrnas?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/MIRNA_logo.svg" alt="miRNA" /></a>
            </li>
            <li title="Revolution in area testing.">
                <a href="https://www.biovendor.com/lamp?utm_source=webgenovesa&amp;utm_medium=web-footer-logo"
                    target="_blank"><img src="/img/bv/LAMP_logo.svg" alt="LAMP" /></a>
            </li>
        </ul>
    </div>

    <div class="last">
        <div class="copyright">
            <strong>&copy; <a href="/">BioVendor Group</a> 2022</strong>
        </div>
        <div class="developed">
            <strong> Developed by <a href="https://www.bioxsys.cz/">BIOXSYS s.r.o.</a> </strong>
        </div>
    </div>
  </footer>

</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapState({
        application: state => state.application,
        token: state => state.token,
      }),
    },

  }

</script>
<style>
  .footer_bg {
    background-color: white !important;
  }

  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
  }

  .okraje {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
</style>
