<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="row">
          <div class="col-6">
            <el-input v-model="searchQuery_e" size="medium" class="top_margin_10" placeholder="Type to search ..." />
          </div>
          <div class="col-5">
            <el-button native-type="button" type="info" size="mini" class="btn btn-info btn-md float-right" @click.native="add_ext_exam()">Add ext. exam.</el-button>
          </div>
          <div class="col-1">
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table class=""
                    ref="examinations_ext"
                    :data="examinations_ext_data"
                    border
                    highlight-current-row
                    @current-change="handle_ext_exam"
                    style="width: 100%"
                    :row-class-name="tableRowClassName"
                    >
            <el-table-column v-for="column in tableColumns_e1"
                              :key="column.label"
                              :min-width="column.minWidth"
                              :prop="column.prop"
                              :label="column.label"
                              sortable
                              >
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">
                <el-tooltip content="Manage metadata of examination" placement="top" effect="light">
                  <p-button type="info" size="sm" icon  @click="handle_ext_exam_detail(props.$index, props.row, $event)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from_e + 1}} to {{to_e}} of {{total_e}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination_e.currentPage"
                        :per-page="pagination_e.perPage"
                        :total="pagination_e.total">
          </p-pagination>
        </div>

        <el-divider></el-divider>

        <div v-if="id_examination_ext > 0" class="col-10">
          <ul>
            <li v-for="ofile in upload_files">
              <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.round(ofile.progress)"></el-progress> {{ofile.name}} - Response: {{ofile.response}}
            </li>
          </ul>
          <file-upload
            class="btn btn-info"
            ref="examination_ext_upload"
            input-id="upload_files"
            v-model="upload_files"
            :multiple="true"
            :size="1024 * 1024 * 1024"
            :extensions="['pdf', 'csv', 'tsv', 'xlsx', 'docx', 'doc', 'html', 'htm', 'odt', 'ods', 'mkv', 'jpg', 'jpeg', 'png', 'tif']"
            :post-action="g_upload_file"
            @input-file="inputFileEE"
            :data="{
                token: this.token,
                my_id: this.id_examination_ext,
                collection: 'examinations_ext'
            }"
          >
          Select File
          </file-upload>
          <span class="mezera"> </span>
          <button class="btn btn-primary" v-show="!$refs.examination_ext_upload || !$refs.examination_ext_upload.active" @click.prevent="$refs.examination_ext_upload.active = true" type="button">Start upload</button>
          <button class="btn btn-warning" v-show="$refs.examination_ext_upload && $refs.examination_ext_upload.active" @click.prevent="$refs.examination_ext_upload.active = false" type="button">Stop upload</button>
        </div>

        <div class="col-2">
          <el-button v-if="upload_files.lenght > 0" type="danger" icon="el-icon-delete" circle></el-button>
        </div>

        <div v-if="id_examination_ext > 0" class="col-12">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <el-table class=""
                        ref="examination_ext_files"
                        :data="examination_ext_files_data"
                        border
                        highlight-current-row
                        @current-change=""
                        style="width: 100%"
                        :row-class-name="tableRowClassName"
                        >
                <el-table-column v-for="column in tableColumns_f"
                                :key="column.label"
                                :min-width="column.minWidth"
                                :prop="column.prop"
                                :label="column.label"
                                sortable
                                >
                </el-table-column>
                <el-table-column
                  :min-width="100"
                  fixed="right"
                  class-name="td-actions"
                  label="Actions">
                  <template slot-scope="props">
                    <el-tooltip content="Download data" placement="top" effect="light">
                      <p-button type="info" size="sm" icon @click="get_mongo_files( props.$index, props.row, $event)">
                        <i class="fas fa-download"></i>
                      </p-button>
                    </el-tooltip>
                    <el-tooltip content="Remove data" placement="top" effect="light">
                      <p-button type="danger" size="sm" icon @click="del_mongo_files( props.$index, props.row, $event)">
                        <i class="fas fa-trash-alt"></i>
                      </p-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{from_f + 1}} to {{to_f}} of {{total_f}} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination_f.currentPage"
                            :per-page="pagination_f.perPage"
                            :total="pagination_f.total"
                            type="light"
                            >
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>

    <modal :show.sync="modal_exam_ext_detail" headerClasses="justify-content-center" :show-close="true">
      <h4 v-if="form_exam_ext.id_examination_ext === 0" slot="header" class="title title-up">Add New Ext. Examination</h4>
      <h4 v-if="form_exam_ext.id_examination_ext > 0" slot="header" class="title title-up">Ext. Examination ID: {{ form_exam_ext.id_examination_ext }}</h4>
      <form @submit.prevent="submit_exam_ext">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-title">Patient</div>
                <el-input type="text" placeholder="" size="small" v-model="form_exam_ext.id_patient"></el-input>

                <div class="sub-title">Type of Examination</div>
                <el-select class="select-neutral"
                          placeholder=""
                          size="small"
                          v-model="form_exam_ext.id_exam_type">
                  <el-option v-for="option in l_exam_types"
                            class="select-info"
                            :value="option.id_exam_type"
                            :label="option.exam_name"
                            :key="option.id_exam_type">
                  </el-option>
                </el-select>

                <div class="sub-title">Doctor</div>
                <el-input type="text" placeholder="" size="small" v-model="form_exam_ext.doctor"></el-input>

                <div class="sub-title">Doctor - Contact Information</div>
                <el-input type="text" placeholder="" size="small" v-model="form_exam_ext.doctor_contact"></el-input>

                <div class="sub-title">Subject - Organization</div>
                <el-input type="text" placeholder="" size="small" v-model="form_exam_ext.subject"></el-input>

                <div class="sub-title">External ID</div>
                <el-input type="text" placeholder="" size="small" v-model="form_exam_ext.external_id"></el-input>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="sub-title">Relation for NGS Examination</div>
                <el-select class="select-neutral"
                          placeholder=""
                          size="small"
                          v-model="form_exam_ext.id_examination">
                  <el-option v-for="option in examinations_patient"
                            class="select-info"
                            :value="option.id_examination"
                            :label="option.sample_id"
                            :key="option.id_examination">
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <div class="sub-title">Description</div>
                <textarea type="textarea" class="form-control" rows="8" placeholder="" size="small" v-model="form_exam_ext.description"> </textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 mr-auto ml-auto">
            <p-button type="info" block @click.native="submit_exam_ext()">Submit</p-button>
          </div>
        </div>
      </form>
    </modal>


  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  import {Table, TableColumn, Select, Option, Tabs, TabPane, Tag, Divider } from 'element-ui';
  import Button from 'src/components/UIComponents/Button';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import Loading from 'src/components/Layout/LoadingMainPanel.vue';
  import Modal from 'src/components/UIComponents/Modal'

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Tabs);
  Vue.use(Select);
  Vue.use(Option);


  export default {
    name: 'pcextexams',
    props: {
    },
    components: {
      PPagination,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Tag.name]: Tag,
      Modal,
      Button,
      Divider,
    },
    computed: {
      ...mapState({
        token: state => state.token,
        user: state => state.user,
        patient: state => state.patient,
        id_patient: state => state.id_patient,
        examinations_ext_patient: state => state.examinations_ext_patient,
        patient_card: state => state.patient_card,
        patient_card_disabled: state => state.patient_card_disabled,
        modal_exam_ext_detail: state => state.modal_exam_ext_detail,
        l_exam_types: state => state.l_exam_types,
        examinations_patient: state => state.examinations_patient,
      }),

      ...mapGetters({
        g_upload_file: 'g_upload_file',

      }),

      pagedData_e () {
        return this.examinations_ext_patient.slice(this.from_e, this.to_e)
      },

      examinations_ext_data () {
        if (!this.searchQuery_e) {
          this.pagination_e.total = this.examinations_ext_patient.length
          return this.pagedData_e
        }
        let result = this.examinations_ext_patient
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_e) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_e)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_e.total = result.length
        return result.slice(this.from_e, this.to_e)
      },

      to_e () {
        let highBound = this.from_e + this.pagination_e.perPage
        if (this.total_e < highBound) {
          highBound = this.total_e
        }
        return highBound
      },

      from_e () {
        return this.pagination_e.perPage * (this.pagination_e.currentPage - 1)
      },

      total_e () {
        this.pagination_e.total = this.examinations_ext_patient.length
        return this.examinations_ext_patient.length
      },

      pagedData_f () {
        return this.examination_ext_files.slice(this.from_f, this.to_f)
      },

      examination_ext_files_data () {
        if (!this.searchQuery_f) {
          this.pagination_f.total = this.examination_ext_files.length
          return this.pagedData_f
        }
        let result = this.examination_ext_files
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_f) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_f)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_f.total = result.length
        return result.slice(this.from_f, this.to_f)
      },

      to_f () {
        let highBound = this.from_f + this.pagination_f.perPage
        if (this.total_f < highBound) {
          highBound = this.total_f
        }
        return highBound
      },

      from_f () {
        return this.pagination_f.perPage * (this.pagination_f.currentPage - 1)
      },

      total_f () {
        this.pagination_f.total = this.examination_ext_files.length
        return this.examination_ext_files.length
      },

      modal_exam_ext_detail: {
        get () {
          return this.$store.state.modal_exam_ext_detail;
        },
        set (value) {
          this.$store.commit('m_modal_exam_ext_detail', value);
        }
      },

    },


    data () {
      return {
        progress_bar_colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 80},
          {color: '#6f7ad3', percentage: 100}
        ],

        files: [],
        formdata_exam: {
          sample_id: "",
          description: "",
        },

        formdata_patient: {
          sample_id: 0,
          description: "",
        },

        formdata_report: {
          description: "",
        },

        form_exam_ext: {
          id_examination_ext: 0,
          id_patient: this.id_patient,
          id_exam_type: 0,
          id_examination: 0,
          started: "",
          doctor: "",
          doctor_contact: "",
          subject: "",
          description: "",
          external_id: "",
        },


        id_examination_ext: 0,
        upload_files: [],
        examination_ext_files: [],

        pagination_e: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5,],
          total: 0
        },

        searchQuery_e: '',
        propsToSearch_e: ['sample_id', 'external_id', 'description', ],
        tableColumns_e1: [
          {
            prop: 'id_examination_ext',
            label: 'ID',
            minWidth: 60,
            align: "right"
          },
          {
            prop: 'exam_name',
            label: 'Type',
            minWidth: 100,
            align: "right"
          },
          {
            prop: 'sample_id',
            label: 'SampleID',
            minWidth: 100,
            align: "right"
          },
          {
            prop: 'external_id',
            label: 'ExtID',
            minWidth: 130,
            align: "right"
          },
          {
            prop: 'description',
            label: 'Description',
            minWidth: 300
          },
        ],


        pagination_f: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5,],
          total: 0
        },
        searchQuery_f: '',
        propsToSearch_f: ['filename', ],
        tableColumns_f: [
          {
            prop: 'filename',
            label: 'File name',
            minWidth: 120,
            align: "left"
          },
          {
            prop: 'md5',
            label: 'MD5',
            minWidth: 120,
            align: "left"
          },


        ],

      }
    },

    methods: {
      tableRowClassName({row, rowIndex}) {
        if (row.status > 18) {
          return 'row_light_gray';
        } else if (row.status > 11) {
          return 'row_light_gray';
        } else if (row.status > 9) {
          return 'row_light_gray';
        } else if (row.status > 8) {
          return 'row_light_gray';
        } else if (row.status > 1) {
          return 'row_light_gray';
        } else if (row.status > 0) {
          return 'row_light_gray';
        } else {
          return 'row_light_gray';
        }
      },


      handle_ext_exam(val) {
        if (val && val.id_examination_ext > 0) {
          this.id_examination_ext = val.id_examination_ext;
          console.log(this.id_examination_ext);
        } else {
          this.id_examination_ext = 0;
        }
      },


      handle_ext_exam_detail (index, row, event) {
        event.stopImmediatePropagation();
        console.log(row.id_examination_ext);
        axios.get(process.env.VUE_APP_BE + '/examination_ext_detail', { withCredentials: true, params: { "id_examination_ext": row.id_examination_ext }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                  console.log(response.data["examination_ext"]);
                  let examination_ext = response.data["examination_ext"];
                  // this.examination_ext_files = response.data["f_list"];

                  this.form_exam_ext.id_examination_ext = examination_ext.id_examination_ext;
                  this.form_exam_ext.id_patient = examination_ext.id_patient;
                  this.form_exam_ext.id_exam_type = examination_ext.id_exam_type;
                  this.form_exam_ext.id_examination = examination_ext.id_examination;
                  this.form_exam_ext.started = examination_ext.started;
                  this.form_exam_ext.doctor = examination_ext.doctor;
                  this.form_exam_ext.doctor_contact = examination_ext.doctor_contact;
                  this.form_exam_ext.subject = examination_ext.subject;
                  this.form_exam_ext.description = examination_ext.description;
                  this.form_exam_ext.external_id = examination_ext.external_id;
                  // this.examination_ext_files = [];
                  this.m_modal_exam_ext_detail(true);
            }, error => {
                console.log(error);
            });


      },

      inputFileEE: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
            axios.post(process.env.VUE_APP_BE + '/get_files', { "collections": "f_examinations_ext", "my_id": this.id_examination_ext  }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                     console.log(response.data["f_list"]);
                }, error => {
                    console.log(error);
                });
          }
        }

      },


      get_mongo_files(index, row, event) {
        event.stopImmediatePropagation();
        axios.post(process.env.VUE_APP_BE + '/get_files', { "collection": "f_examinations_ext", "my_id": this.id_examination_ext }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                  this.examination_ext_files = response.data["f_list"];
            }, error => {
                console.log(error);
            });
      },

      del_mongo_files(index, row, event) {
        event.stopImmediatePropagation();
        axios.post(process.env.VUE_APP_BE + '/del_files', { "collection": "f_examinations_ext", "my_id": this.id_examination_ext, "my_md5": row.md5 }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                  this.examination_ext_files = response.data["f_list"];
            }, error => {
                console.log(error);
            });
      },



      handle_exam_detail (index, row, event) {
        event.stopImmediatePropagation();
        // this.formdata_ep.id_project = row.id_project;
        // this.formdata_ep.name = row.name;
        // this.formdata_ep.description = row.description;
        this.m_id_examination_detail(row.id_examination);
        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        // this.formdata_exam.sample_id = this.g_examination.sample_id;
        // this.formdata_exam.description = this.g_examination.description;
        this.m_modal_exam_detail(true);
      },

      handle_exam_validate (index, row, event, type) {
        event.stopImmediatePropagation();
        this.a_examination_validate( {"id_examination": row.id_examination, "act": type} );

        // if (row.status === 10) {
        //   row.status = 11;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else if (row.status > 10 && row.status < 20) {
        //   row.status = 21;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else if (row.status > 10 && row.status < 20) {
        //   row.status = 21;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else {
        //   row.status = 9;
        // }
        // this.m_id_examination_lock(row.id_examination);
        // this.m_modal_project_detail(true);
      },

      handle_exam_delete (index, row, event) {
        event.stopImmediatePropagation();
        this.a_examination_delete( {'id_examination': row.id_examination} );
      },

      handleShowQC (index, row, event, qc_type) {
        event.stopImmediatePropagation();
        this.a_show_qc( { "id_examination": row.id_examination, "type": qc_type } );
      },

      handle_exam_reload (event) {
        event.stopImmediatePropagation();
        this.a_examinations( {"id_project": this.id_project} );
      },

      handle_unavailable (index, row, event) {
        event.stopImmediatePropagation();
        alert(`Not available in trial version.`);
      },

      handle_close_modal () {
        console.log("Zaviram modal.");
      },



      ...mapMutations({
        m_id_examination: 'm_id_examination',
        m_modal_transcripts: 'm_modal_transcripts',
        m_modal_patient: 'm_modal_patient',
        m_modal_exam_detail: 'm_modal_exam_detail',
        m_id_examination_detail: 'm_id_examination_detail',
        m_modal_exam_ext_detail: 'm_modal_exam_ext_detail',
      }),


      ...mapActions({
        a_patient_card: 'a_patient_card',

      }),


      handleCurrentChange_e(val) {
        if (val && val.id_examination > 0 && val.status > 8) {
          this.m_id_examination(val.id_examination);
          this.m_id_examination_detail(val.id_examination);
          this.a_vars( {"cufi": this.cufi, "id_examination": this.id_examination} );
        } else if (val && val.id_examination > 0) {
          this.m_id_examination(0);
          this.m_id_examination_detail(0);
        } else {
          this.m_id_examination(0);
          this.m_id_examination_detail(0);
        }
      },

      handlePatient(row, event) {
        event.stopImmediatePropagation();
        this.m_modal_patient(true);
        this.a_patient( row.id_patient );



      },

      submit_exam() {
        // this.a_change_interpretation( this.formdata_change_class );
        // this.a_vars( {"order": this.vars_order, "er_switch": this.er_switch, "cufi": this.cufi, "id_examination": this.id_examination} );
        // this.a_notify("Interpretation was changed.");
      },

      submit_patient() {

      },

      submit_exam_ext() {
        event.stopImmediatePropagation();
        axios.post(process.env.VUE_APP_BE + '/examination_ext_detail', { "data": this.form_exam_ext }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                  this.examination_ext_files = response.data["f_list"];
                  this.form_exam_ext.id_examination_ext = 0;
                  this.form_exam_ext.id_patient = this.id_patient;
                  this.form_exam_ext.id_exam_type = 0;
                  this.form_exam_ext.id_examination = 0;
                  this.form_exam_ext.started = "";
                  this.form_exam_ext.doctor = "";
                  this.form_exam_ext.doctor_contact = "";
                  this.form_exam_ext.subject = "";
                  this.form_exam_ext.description = "";
                  this.form_exam_ext.external_id = "";
                  this.examination_ext_files = [];
            }, error => {
                console.log(error);
            });

        this.m_modal_exam_ext_detail(false);
      },

      add_ext_exam() {
        event.stopImmediatePropagation();
        this.form_exam_ext.id_examination_ext = 0;
        this.form_exam_ext.id_patient = this.id_patient;
        this.form_exam_ext.id_exam_type = 0;
        this.form_exam_ext.id_examination = 0;
        this.form_exam_ext.started = "";
        this.form_exam_ext.doctor = "";
        this.form_exam_ext.doctor_contact = "";
        this.form_exam_ext.subject = "";
        this.form_exam_ext.description = "";
        this.form_exam_ext.external_id = "";
        this.examination_ext_files = [];
        this.m_modal_exam_ext_detail(true);
      },

    },


    watch: {
      id_examination_ext: function () {
        axios.post(process.env.VUE_APP_BE + '/get_files', { "collection": "f_examinations_ext", "my_id": this.id_examination_ext }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                  this.examination_ext_files = response.data["f_list"];
                  console.log(this.examination_ext_files);
            }, error => {
                console.log(error);
            });
      },
    },



  }
</script>

<style lang="scss" scoped>
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}

.el-table {
  font-size: 12px !important;
}

.el-table .cell{
  line-height: 20px !important;
}

.el-table td, .el-table th {
  padding: 3px 0 !important;
}

.el-table .row_0 {
  background: rgb(253, 253, 253) !important;
}

.el-table .row_1 {
  background: rgb(243, 245, 152) !important;
}

.el-table .row_2 {
  background: rgb(248, 221, 171) !important;
}

.el-table .row_9 {
  background: rgb(252, 192, 82) !important;
}

.el-table .row_10 {
  background: rgb(120, 244, 248) !important;
}

.el-table .row_12 {
  background: rgb(154, 240, 168) !important;
}

.el-table .row_19 {
  background: rgb(5, 153, 29) !important;
}

.el-table .row_light_gray {
  background: rgb(243, 243, 243) !important;
}


.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #06d410 !important;
}

.el-table .info-row {
  background: #8fbdf1 !important;
}

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}

.table > thead > tr > th, .el-table table > thead > tr > th {
  font-size: 12px !important;
}

.mezera {
  margin-right: 10px;
}

.top_margin_10 {
  margin-top: 10px;
}


</style>
