<template>
  <div class="wrapper">
    <div class="row dolu">
      <div class="col-12">
        <register>

        </register>
      </div>
    </div>

    </div>
  </div>

</template>
<style lang="scss">

</style>
<script>
  // import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
    import Register from './Register.vue'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import axios from 'axios';
  import VueNotify from 'vue-notifyjs'

  export default {
    data(){
        return {
          // form: {
          //   email: "",
          //   password: "",
          // },
          // form_reg: {
          //   degrees_1: "",
          //   firstName: "",
          //   lastName: "",
          //   degrees_2: "",
          //   password: "",
          //   verify: "",
          //   email: "",
          //   phone: "",
          //   acceptTerms: true,
          // },
          // form_forgot: {
          //   email: "",
          // },
        }
      }, 
    components: {
      Register,
    },

    computed: {
    },

    methods: {
    }
  }


</script>
<style lang="scss">


</style>