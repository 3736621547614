import Vue from 'vue';
import './pollyfills';
import moment from 'moment';
Vue.prototype.moment = moment
moment.locale("cs");
import {
    Pagination,
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    TimeSelect,
    TimePicker,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Tree,
    Alert,
    Slider,
    Icon,
    Row,
    Col,
    Upload,
    Progress,
    Spinner,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    Cascader,
    ColorPicker,
    Transfer,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Timeline,
    TimelineItem,
    Link,
    Divider,
    Image,
    Calendar,
    Backtop,
    PageHeader,
    CascaderPanel,
    Loading,
    MessageBox,
    Message,
    Notification,
    InfiniteScroll
} from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import VueRouterPrefetch from 'vue-router-prefetch';
import VueNotify from 'vue-notifyjs';
import { ValidationProvider } from 'vee-validate';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);

import axios from 'axios';
import App from './App.vue';

import { Laue } from 'laue';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import SideBar from './components/UIComponents/SidebarPlugin';
import Modal from './components/UIComponents/Modal';
import initProgress from './progressbar';;

// router setup
import routes from './routes/routes';
import store from './stores';

// library imports
import './assets/sass/genovesa.scss';


import sidebarLinks from './sidebarLinks';
import './registerServiceWorker';


Vue.use(moment);
// Vue.use(axios);

// plugin setup
Vue.use(VueRouter);
Vue.use(VueRouterPrefetch);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(Modal);

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);
Vue.use(InfiniteScroll);

Vue.use(Laue);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

// import VIgv from 'igv-vue'

// Vue.use(VIgv, {
//     reference: {
//         'id': 'hg38',
//         'name': 'Human (GRCh38/hg38)',
//         // 'fastaURL': 'http://localhost:8100/static/hg38.fa',
//         // 'indexURL': 'http://localhost:8100/static/hg38.fa.fai',
//         // 'cytobandURL': 'http://localhost:8100/static/cytoBandIdeo.txt',
//         'tracks': [{
//             'name': 'Refseq Genes',
//             // 'url': 'http://localhost:8100/static/refGene.txt.gz',
//             'order': 1000000,
//             'indexed': false
//         }]
//     },
//     locus: 'chr1:1-2',
//     showCenterGuide: true,
//     baseUrl: 'http://localhost:8100/static'
// })


// configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    linkActiveClass: 'active',
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }
})

Vue.use(router)

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/', '/welcome', '/registration', ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next('/welcome');
    }
    // console.log(navigator);
    next();
})


// router.beforeEach((to, from, next) => {
    // localStorage.removeItem("token");
    // const myPromise = new Promise(function(resolve, reject) {
    //     let my_path = to.name;
    //     if ('x_my_token' in to.query && to.query.my_token) {
    //         let my_token = to.query.my_token;

    //         axios.get(process.env.VUE_APP_BE + '/verify_token', { withCredentials: true, params: { "token": my_token }, headers: { 'Content-Type': 'application/json;charset=UTF-8', } })
    //         .then(response => {
    //             if (response.data["token"] === "") {
    //                 localStorage.removeItem("token");
    //                 console.log("xNENAM TOKEN");
    //             } else {
    //                 localStorage.setItem('token', my_token);
    //                 console.log("xTOKEN ULOZEN");
    //             }

    //         }, error => {
    //             console.log(error);
    //         });
    //     }
    //     resolve('OK');
    // });

    // setTimeout(function() {
    //     myPromise
    //         .then(function whenOk(response) {
    //             if ('x_my_token' in to.query && to.query.my_token) {
    //                 console.log("XXXXXXXX");
    //                 return next('/');

    //             }
    //             const publicPages = ['/', '/welcome', ];
    //             const authRequired = !publicPages.includes(to.path);
    //             const loggedIn = localStorage.getItem('token');

    //             if (authRequired && !loggedIn) {
    //                 return next('/welcome');
    //             }
    //             next();
    //         })
    //         .catch(function notOk(err) {
    //             const publicPages = ['/', '/welcome', ];
    //             const authRequired = !publicPages.includes(to.path);
    //             const loggedIn = localStorage.getItem('token');

    //             if (authRequired && !loggedIn) {
    //                 return next('/welcome');
    //             }
    //             next();
    //         })

    // }, 200);


// });


initProgress(router);

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,

    render: h => h(App),

    created() {
        var currentUrl = window.location;

        if (currentUrl.search.substring(1,9) === "my_token") {
            let my_token = currentUrl.search.substring(10);
            console.log(my_token);

            const myPromise = new Promise(function(resolve, reject) {
                axios.get(process.env.VUE_APP_BE + '/verify_token', { withCredentials: true, params: { "token": my_token }, headers: { 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (response.data["token"] === "") {
                        localStorage.removeItem("token");
                        console.log("NENAM TOKEN");
                    } else {
                        localStorage.setItem('token', my_token);

                        console.log("TOKEN ULOZEN");
                    }

                }, error => {
                    console.log(error);
                });
            });

            setTimeout(function () {
                myPromise
                    .then(function whenOk(response) {
                        console.log("XXXXXXXX");
                    })
                    .catch(function notOk(err) {

                    })

            }, 200);

        }

        // router.beforeEach((to, from, next) => {
        //     this.$store.commit('m_rapp', to.matched[0].name);
        //     next();
        // })

        // axios.get(process.env.VUE_APP_BE + '/')
        //     .then(response => {
        //         this.$store.commit('m_l_icd11', response.data["l_icd11"]);
        //         this.$store.commit('m_l_ethnic', response.data["l_ethnic"]);
        //         this.$store.commit('m_l_countries', response.data["l_countries"]);
        //         this.$store.commit('m_l_gene', response.data["l_gene"]);
        //         this.$store.commit('m_l_consequences', response.data["l_consequences"]);
        //         this.$store.commit('m_l_features', response.data["l_features"]);
        //         this.$store.commit('m_l_clinvars', response.data["l_clinvars"]);
        //         this.$store.commit('m_l_clinvar_dns', response.data["l_clinvar_dns"]);
        //         this.$store.commit('m_d_a_cols', response.data["d_a_cols"]);
        //     }, error => {
        //         console.log(error);
        //     });

        if (this.$store.state.token) {
            this.$store.dispatch('a_load_profile');
        } else {

        }

        var application;
        var myrapp = currentUrl.pathname.substring(1).split("/");
        this.$store.commit('m_rapp', myrapp[0]);

        this.$store.commit('m_current_url', currentUrl);

        if (currentUrl.hostname === "localhost") {
            this.$store.commit('m_privat_be', "http://localhost:8199");
        }


    },

    mounted() {
        var currentUrl = window.location;

        if (currentUrl.search.substring(1,9) === "my_token") {
            let my_token = currentUrl.search.substring(10);
            console.log(my_token);

            axios.get(process.env.VUE_APP_BE + '/verify_token', { withCredentials: true, params: { "token": my_token }, headers: { 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                if (response.data["token"] === "") {
                    console.log("m_NENAM TOKEN");
                } else {
                    this.$store.commit('m_token', my_token);

                    axios.get(process.env.VUE_APP_BE + '/load_profile', { headers: { 'Authorization': my_token } }, { withCredentials: true, headers: { 'Authorization': my_token, 'Content-Type': 'application/json;charset=UTF-8', } })
                    .then(response => {
                        this.$store.commit('m_subject', response.data["subject"]);
                        this.$store.commit('m_user', response.data["user"]);
                        this.$store.commit('m_apps', response.data["apps"]);

                        if (response.data["token"] === "") {
                            this.$store.commit('m_token', "");
                            localStorage.removeItem("token");
                        }

                        // commit('m_workflows', response.data["workflows"]);

                        // if (state.current_url.hostname !== "localhost") {
                            // commit('m_privat_be', response.data["subject"]["privat_api"]);
                            // commit('m_privat_be', "/pv");
                        // }

                    }, error => {
                        console.log(error);
                    });


                    axios.get(process.env.VUE_APP_BE + '/', { headers: { 'Authorization': my_token } }, { withCredentials: true, headers: { 'Authorization': my_token, 'Content-Type': 'application/json;charset=UTF-8', } })
                    .then(response => {
                        this.$store.commit('m_l_iclasses', response.data["l_iclasses"]);
                        this.$store.commit('m_d_acmg_sigs', response.data["d_acmg_sigs"]);
                        this.$store.commit('m_l_icd11', response.data["l_icd11"]);
                        this.$store.commit('m_l_ethnic', response.data["l_ethnic"]);
                        this.$store.commit('m_l_countries', response.data["l_countries"]);
                        this.$store.commit('m_l_exam_types', response.data["l_exam_types"]);
                        this.$store.commit('m_d_pseudopanels', response.data["d_pseudopanels"]);
                        this.$store.commit('m_l_consequences', response.data["l_consequences"]);
                        this.$store.commit('m_l_features', response.data["l_features"]);
                        this.$store.commit('m_l_clinvars', response.data["l_clinvars"]);
                        this.$store.commit('m_d_clinvars', response.data["d_clinvars"]);
                        this.$store.commit('m_l_clinvar_dns', response.data["l_clinvar_dns"]);
                        // this.$store.commit('m_cufi_my_iclasses', response.data["l_iclasses"]);
                        // this.$store.commit('m_cufi_default_my_iclasses', response.data["l_iclasses"]);
                        this.$store.commit('m_d_a_cols', response.data["d_a_cols"]);
                        this.$store.commit('m_l_seq_technology', response.data["l_seq_technology"]);
                        this.$store.commit('m_d_filter', response.data["d_filter"]);
                        this.$store.commit('m_d_cufis', response.data["d_cufis"]);
                        this.$store.commit('m_l_cufis', response.data["l_cufis"]);

                        // this.$store.commit('m_qc_a', response.data["qc_a"]);
                        // this.$store.commit('m_qc_b', response.data["qc_b"]);

                    }, error => {
                        console.log(error);
                    });


                }

            }, error => {
                console.log(error);
            });


        }


        if (this.$store.state.token) {
            axios.get(process.env.VUE_APP_BE + '/', { headers: { 'Authorization': this.$store.state.token } }, { withCredentials: true, headers: { 'Authorization': this.$store.state.token, 'RouterApp': this.$store.state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                this.$store.commit('m_l_iclasses', response.data["l_iclasses"]);
                this.$store.commit('m_d_acmg_sigs', response.data["d_acmg_sigs"]);
                // this.$store.commit('m_l_seq_technology', response.data["l_seq_technology"]);
                this.$store.commit('m_d_a_cols', response.data["d_a_cols"]);
                this.$store.commit('m_l_icd11', response.data["l_icd11"]);
                this.$store.commit('m_l_ethnic', response.data["l_ethnic"]);
                this.$store.commit('m_l_countries', response.data["l_countries"]);
                this.$store.commit('m_l_exam_types', response.data["l_exam_types"]);
                this.$store.commit('m_d_pseudopanels', response.data["d_pseudopanels"]);
                this.$store.commit('m_l_consequences', response.data["l_consequences"]);
                this.$store.commit('m_l_features', response.data["l_features"]);
                this.$store.commit('m_l_clinvars', response.data["l_clinvars"]);
                this.$store.commit('m_d_clinvars', response.data["d_clinvars"]);
                this.$store.commit('m_l_clinvar_dns', response.data["l_clinvar_dns"]);
                this.$store.commit('m_d_filter', response.data["d_filter"]);
                this.$store.commit('m_d_cufis', response.data["d_cufis"]);
                this.$store.commit('m_l_cufis', response.data["l_cufis"]);

                // this.$store.commit('m_cufi_my_iclasses', response.data["l_iclasses"]);
                // this.$store.commit('m_cufi_default_my_iclasses', response.data["l_iclasses"]);
                // this.$store.commit('m_qc_a', response.data["qc_a"]);
                // this.$store.commit('m_qc_b', response.data["qc_b"]);
            }, error => {
                console.log(error);
            });

        } else {

        }


        // if (this.$store.state.token) {
        //     this.$store.dispatch('a_load_profile');
        // }

        // var currentUrl = window.location;
        // this.$store.commit('m_current_url', currentUrl);

        // if (currentUrl.hostname === "localhost") {
        //     this.$store.commit('m_privat_be', "http://localhost:8199");
        // }

        var currentUrl = window.location;
        var application;
        if (currentUrl.hostname === "viennalab.bioxsys.cz") {
            application = "ViennaLab"
        } else if (currentUrl.hostname === "ngs-genovesa.biovendor.group") {
            application = "NGS-GENOVESA"
        } else if (currentUrl.hostname === "ngs-genovesa-demo.biovendor.group") {
            application = "NGS-GENOVESA-DEMO"
        } else {
            application = "GENOVESA"
        }

        // application = "NGS-GENOVESA"

        this.$store.commit('m_application', application);

        if (application === "ViennaLab") {
            var element = document.createElement('link');
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute("href", "/css/viennalab.css");
            document.getElementsByTagName("head")[0].appendChild(element);
        } else if (application === "NGS-GENOVESA") {
            var element = document.createElement('link');
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute("href", "/css/biovendor.css");
            document.getElementsByTagName("head")[0].appendChild(element);

            // var element1 = document.createElement('link');
            // element.setAttribute("rel", "stylesheet");
            // element.setAttribute("type", "text/css");
            // element.setAttribute("href", "https://use.typekit.net/iys0rvv.css");
            // document.getElementsByTagName("head")[0].appendChild(element1);

            // var element2 = document.createElement('link');
            // element.setAttribute("rel", "stylesheet");
            // element.setAttribute("type", "text/css");
            // element.setAttribute("href", "https://www.biovendor.com/content/33/styles/style-home.css");
            // document.getElementsByTagName("head")[0].appendChild(element2);

        } else if (application === "NGS-GENOVESA-DEMO") {
            var element = document.createElement('link');
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute("href", "/css/biovendor.css");
            document.getElementsByTagName("head")[0].appendChild(element);

        } else {
            var element = document.createElement('link');
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute("href", "/css/genovesa.css");
            document.getElementsByTagName("head")[0].appendChild(element);
        }



    },

})



export default router
