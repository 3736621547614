<template>
  <div class="row dolu">
    <div class="col-12">
      <edit-profile-form>

      </edit-profile-form>
    </div>

    <div class="col-12">
      <members-card>

      </members-card>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import MembersCard from './UserProfile/MembersCard.vue'

  export default {
    components: {
      EditProfileForm,
      MembersCard
    }
  }

</script>
<style>
.dolu {
  padding-top: 80px !important;
}
</style>
