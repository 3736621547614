import Layout from '../components/Layout/Layout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'

import Overview from '../components/Views/Dashboard/Overview.vue'
import Widgets from '../components/Views/Dashboard/Widgets.vue'
import Welcome from '../components/Views/Dashboard/Welcome.vue'
import Workflow from '../components/Views/Workflow.vue'

import Charts from '../components/Views/Charts.vue'


//  pages
// const Overview = () =>
// import ( /* webpackChunkName: "widgets" */ 'src/components/Views/Dashboard/Overview.vue')
// const Widgets = () =>
// import ( /* webpackChunkName: "widgets" */ 'src/components/Views/Dashboard/Widgets.vue')
// const Welcome = () =>
// import ( /* webpackChunkName: "widgets" */ 'src/components/Views/Dashboard/Welcome.vue')
// Pages
import User from 'src/components/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Views/Pages/Login.vue'
import Registration from 'src/components/Views/Pages/Registration.vue'
import JoinSubject from 'src/components/Views/Pages/JoinSubject.vue'
import Lock from 'src/components/Views/Pages/Lock.vue'

import ProjectsInternal from 'src/components/Views/Projects/Internal.vue'
import ProjectsExternal from 'src/components/Views/Projects/External.vue'

import PatientsAll from 'src/components/Views/PatientsAll.vue'
import Statim from 'src/components/Views/Patients/Statim.vue'
import ByVariant from 'src/components/Views/Patients/ByVariant.vue'

import Vars from 'src/components/Views/Vars.vue'
// import VariantsAll from 'src/components/Views/Variants/VariantsAll.vue'

import AnWorkflows from 'src/components/Views/Analyses/AnWorkflows.vue'
import AnGenes from 'src/components/Views/Analyses/AnGenes.vue'

import Accounts from 'src/components/Views/Settings/Accounts.vue'
import Workflows from 'src/components/Views/Settings/Workflows.vue'

import ReportsFullList from 'src/components/Views/Reports/FullList.vue'

import FavoritesFullList from 'src/components/Views/Favorites/FullList.vue'

import StartChat from 'src/components/Views/Chat/StartChat.vue'

import BlankPage from 'src/components/Views/BlankPage.vue'

// Components pages
import Buttons from 'src/components/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Views/Components/GridSystem.vue'
import Panels from 'src/components/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Views/Components/Notifications.vue'
import Icons from 'src/components/Views/Components/Icons.vue'
import Typography from 'src/components/Views/Components/Typography.vue'


let proMenu = {
    path: '/pro',
    name: 'pro',
    props: true,
    component: Layout,
    redirect: '/pro/internal',
    children: [{
            path: 'internal',
            name: 'proProjectsInternal',
            component: ProjectsInternal,
            props: { rapp: 'pro' }
        },
        {
            path: 'patients',
            name: 'proPatientsAll',
            component: PatientsAll,
            props: { rapp: 'pro' }
        },
        // {
        //     path: 'statim',
        //     name: 'proStatim',
        //     component: Statim,
        //     props: { rapp: 'pro' }
        // },
        // {
        //     path: 'byvariant',
        //     name: 'proByVariant',
        //     component: ByVariant,
        //     props: { rapp: 'pro' }
        // },
        // {
        //     path: 'variantsall',
        //     name: 'proVariantsAll',
        //     component: VariantsAll,
        //     props: { rapp: 'pro' }
        // },
        {
            path: 'dashboard',
            name: 'proDashboard',
            component: Overview,
            props: { rapp: 'pro' }
        },
    ]
}



let lightMenu = {
    path: '/light',
    name: 'light',
    props: true,
    component: Layout,
    redirect: '/light/internal',
    children: [{
            path: 'internal',
            name: 'lightProjectsInternal',
            component: ProjectsInternal,
            props: { rapp: 'light' }
        },
        {
            path: 'patients',
            name: 'lightPatientsAll',
            component: PatientsAll,
            props: { rapp: 'light' }
        },
        {
            path: 'statim',
            name: 'lightStatim',
            component: Statim,
            props: { rapp: 'light' }
        },
        {
            path: 'byvariant',
            name: 'lightByVariant',
            component: ByVariant,
            props: { rapp: 'light' }
        },
        {
            path: 'dashboard',
            name: 'lightDashboard',
            component: Overview,
            props: { rapp: 'light' }
        },
    ]
}


let fastrasMenu = {
    path: '/fastras',
    name: 'fastras',
    component: Layout,
    redirect: '/fastras/internal',
    children: [{
            path: 'internal',
            name: 'fastrasProjectsInternal',
            component: ProjectsInternal,
            props: { rapp: 'fastras' }
        },
        {
            path: 'patients',
            name: 'fastrasPatientsAll',
            component: PatientsAll,
            props: { rapp: 'fastras' }
        },
        {
            path: 'statim',
            name: 'fastrasStatim',
            component: Statim,
            props: { rapp: 'fastras' }
        },
        {
            path: 'byvariant',
            name: 'fastrasByVariant',
            component: ByVariant,
            props: { rapp: 'fastras' }
        },
        {
            path: 'dashboard',
            name: 'fastrasDashboard',
            component: Overview,
            props: { rapp: 'fastras' }
        },
    ]
}



let genetifyMenu = {
    path: '/genetify',
    name: 'genetify',
    component: Layout,
    redirect: '/genetify/internal',
    children: [{
            path: 'internal',
            name: 'genetifyProjectsInternal',
            component: ProjectsInternal,
            props: { rapp: 'genetify' }
        },
        {
            path: 'dashboard',
            name: 'genetifyDashboard',
            component: Overview,
            props: { rapp: 'genetify' }
        },
    ]
}


let analysesMenu = {
    path: '/analyses',
    component: Layout,
    redirect: '/analyses/workflows',
    children: [{
            path: 'anworkflows',
            name: 'AnWorkflows',
            component: AnWorkflows
        },
        {
            path: 'angenes',
            name: 'AnGenes',
            component: AnGenes
        },
    ]
}

let settingsMenu = {
    path: '/settings',
    component: Layout,
    redirect: '/settings/accounts',
    children: [{
            path: 'accounts',
            name: 'Accounts',
            component: Accounts
        },
        {
            path: 'workflows',
            name: 'Workflows',
            component: Workflows
        },
    ]
}

let favoritesMenu = {
    path: '/favorites',
    component: Layout,
    redirect: '/favorites/fulllist',
    children: [{
        path: 'fulllist',
        name: 'FavoritesFullList',
        component: FavoritesFullList
    }, ]
}

let reportsMenu = {
    path: '/reports',
    component: Layout,
    redirect: '/reports/fulllist',
    children: [{
        path: 'fulllist',
        name: 'ReportsFullList',
        component: ReportsFullList
    }, ]
}

let chatMenu = {
    path: '/chat',
    component: Layout,
    redirect: '/chat/startchat',
    children: [{
        path: 'startchat',
        name: 'StartChat',
        component: Charts
    }, ]
}


let pagesMenu = {
    path: '/pages',
    component: Layout,
    redirect: '/pages/user',
    children: [{
            path: 'user',
            name: 'User Page',
            component: User
        },
        {
            path: 'timeline',
            name: 'Timeline Page',
            component: TimeLine
        },
        {
            path: 'login',
            name: 'Login page',
            component: Login
        }
    ]
}

// let loginPage = {
//     path: '/login',
//     name: 'Login',
//     component: Login
// }

// let loginPage = {
//     path: '#/login',
//     component: Layout,
//     redirect: '/login/sign_in',
//     children: [{
//             path: 'login',
//             name: 'Login Page',
//             component: Login
//         },
//         // {
//         //     path: 'timeline',
//         //     name: 'Timeline Page',
//         //     component: TimeLine
//         // }
//     ]
// }


let lockPage = {
    path: '/lock',
    name: 'Lock',
    component: Lock
}


const routes = [{
        path: '/',
        redirect: 'welcome',
        component: Layout,
        children: [{
                path: 'welcome',
                name: 'Welcome',
                component: Welcome
            },
            {
                path: 'registration',
                name: 'Registration',
                component: Registration
            },
            {
                path: 'join_subject',
                name: 'JoinSubject',
                component: JoinSubject
            },
            { path: '/workflow', redirect: '/workflow/0' },
            {
                path: 'workflow/:id_workflow',
                name: 'Workflow',
                component: Workflow
            },
        ]
    },
    proMenu,
    lightMenu,
    fastrasMenu,
    genetifyMenu,

    // variantsMenu,
    // analysesMenu,
    // settingsMenu,
    // favoritesMenu,
    // reportsMenu,
    // chatMenu,
    // loginPage,
    lockPage,
    {
        path: '/admin',
        component: Layout,
        redirect: '/admin/overview',
        children: [{
                path: 'overview',
                name: 'Overview',
                component: Overview
            },
            {
                path: 'widgets',
                name: 'Widgets',
                component: Widgets
            },
            {
                path: 'my_profile',
                name: 'My Profile',
                component: User
            },
        ]
    },
    { path: '*', component: NotFound }
];

export default routes
