<template>
  <card class="fixedbox_1">
    <template slot="header">
      <h4 class="card-title">Chat</h4>
      <p class="category">Seznam uživatelů</p>
    </template>
    <div class="table-full-width table-tasks">
      <table class="table">
        <tbody>
        
        <task v-for="(task, index) in tasks"
              :key="task.title"
              :task="task"
              :index="index"
              :class="task.color"
              >
        </task>
        </tbody>
      </table>
    </div>

  </card>
</template>
<script>
  import Task from './Task.vue'
  import { Card } from 'src/components/UIComponents'

  export default {
    components: {
      Task,
      Card
    },
    data() {
      return {
        tasks: [
          {
            done: true,
            color: "blue",
            title: 'Roman Vomáčka'
          },
          {
            done: false,
            color: "grau1",            
            title: 'Petra Količná Vostrá'
          },
          {
            done: true,
            color: "grau2",            
            title: 'Simona Petráková'
          },
          {
            done: false,
            color: "orange",            
            title: 'Radovan Novotný'
          }
        ]
      }
    
    },
    methods: {

    }
  }
</script>
<style>
.fixedbox_1 {
  height: 700px !important;
}

.blue {
  color: rgb(56, 177, 247) !important;
}

.grau2 {
  color: rgb(68, 71, 78) !important;
}

.grau1 {
  color: rgb(172, 173, 180) !important;
}

.orange {
  color: rgb(173, 72, 24) !important;
}

</style>
