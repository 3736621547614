<template>
  <div class="row dolu">
    <div class="col-md-12">
      <h4 class="title">Choose your role in the subject:</h4>

    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category"></div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <el-table
            ref="roles"
            :data="roles"
            border
            highlight-current-row
            @current-change="handleCurrentChange"
            >
            <el-table-column min-width="50" prop="id_role" label="ID">
            </el-table-column>

            <el-table-column min-width="100" prop="name" label="ROLE">
            </el-table-column>

            <el-table-column min-width="50" prop="id_subject" label="SUBJECT ID" sortable>
            </el-table-column>

            <el-table-column min-width="200" prop="subject" label="SUBJECT" sortable>
            </el-table-column>

            <el-table-column min-width="150" prop="ic" label="IC" sortable>
            </el-table-column>

          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from 'src/components/Layout/LoadingMainPanel.vue'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import axios from 'axios';

  export default {
    components: {

    },


    computed: {
      ...mapState({
        roles: state => state.roles,
        my_role: state => state.my_role,

      }),
    },


    data () {
      return {

      }
    },


    methods: {
      ...mapActions({
        a_my_role: 'a_my_role',

      }),


      handleLike (index, row) {
        alert(`Your want to like ${row.name}`)
      },


      handleEdit (index, row) {
        alert(`Your want to edit ${row.name}`)
      },


      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },


      alertuj (props) {
        alert(props.row.id_role);
        console.log(props.row.id_role);
      },


      handleCurrentChange(val) {
        if (val && val.id_role > 0) {
          this.a_my_role(val.id_role);
        } else {
          this.a_my_role(0);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }



  .el-table td, .el-table th {
    padding: 10px 0 !important;
  }

  h4 {
    margin-top: 5px !important;
  }

  .dolu {
    padding-top: 80px !important;
  }
</style>
