<template>
  <div class="row">
    <div class="col-md-6">
      <h3 class="title">Patients</h3>
    </div>
    <div class="col-md-6">
      <p-button native-type="button" type="info" class="btn btn-info btn-md float-right" @click.native="add_patient()">Add patient</p-button>
    </div>

    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination_pat.perPage"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in pagination_pat.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
          </div>
        </div>

        <div class="col-sm-12 mt-2">
          <el-table class="bg_white"
                    ref="patients"
                    :data="patients_data"
                    border
                    fit
                    stripe
                    highlight-current-row
                    @current-change="handleCurrentChange_pat"
                    style="width: 100%"
                    size="mini"
                    row-key="id_patient"
                    >
            <el-table-column
              prop="id_patient"
              fixed
              label="ID"
              min-width="100"
              align="right"
              >
              <!-- <template v-if="scope.row.ints >= 1" slot-scope="scope">
                    <el-button v-if="scope.row.ints === 1" size="small" type="success" @click.native="alertuj(scope.row.ints)">{{ scope.row.ints }}</el-button> 
                    <el-button v-else-if="scope.row.ints === 2" size="small" type="success" @click.native="alertuj(scope.row.ints)">{{ scope.row.ints }}</el-button> 
                    <el-button v-else-if="scope.row.ints === 3" size="small" type="info" @click.native="alertuj(scope.row.ints)">{{ scope.row.ints }}</el-button> 
                    <el-button v-else-if="scope.row.ints === 4" size="small" type="warning" @click.native="alertuj(scope.row.ints)">{{ scope.row.ints }}</el-button> 
                    <el-button v-else-if="scope.row.ints === 5" size="small" type="danger" @click.native="alertuj(scope.row.ints)">{{ scope.row.ints }}</el-button> 
                    <el-button v-else size="small" type="info">{{ scope.row.ints }}</el-button> 
              </template> -->
            </el-table-column>

            <el-table-column
              prop="name"
              label="Name"
              min-width="150"
              align="left"
              >
            </el-table-column>

            <el-table-column
              prop="surname"
              label="Surname"
              min-width="150"
              align="Left"
              >
            </el-table-column>

            <el-table-column
              prop="birth_no"
              label="Birth No."
              min-width="140"
              align="center"
              >
            </el-table-column>

            <el-table-column
              prop="birth_date"
              label="Birth Date"
              min-width="100"
              align="center"
              >
              <template slot-scope="scope">
                    {{ moment(scope.row.birth_date).format('DD. MM. YYYY') }}
              </template>              
            </el-table-column>

            <el-table-column min-width="100"
                            header-align="right"
                            label="Actions">
              <div slot-scope="{row}" class="text-right">
                <el-tooltip content="Info"
                            :open-delay="300"
                            placement="top">
                  <p-button type="info space_buttons" size="sm" icon>
                    <i class="nc-icon nc-alert-circle-i"></i>
                  </p-button>
                </el-tooltip>

                <el-tooltip content="Settings"
                            :open-delay="300"
                            placement="top">
                  <p-button type="success space_buttons" size="sm" icon>
                    <i class="nc-icon nc-settings"></i>
                  </p-button>
                </el-tooltip>

                <el-tooltip content="Delete"
                            :open-delay="300"
                            placement="top">
                  <p-button type="danger space_buttons" size="sm" icon>
                    <i class="nc-icon nc-simple-delete"></i>
                  </p-button>
                </el-tooltip>

              </div>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from_pat + 1}} to {{to_pat}} of {{total_pat}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination_pat.currentPage"
                        :per-page="pagination_pat.perPage"
                        :total="pagination_pat.total"
                        >
          </p-pagination>
        </div>
      </div>
    </div> 

  </div>
</template>
<script>
  import Vue from 'vue';
  import moment from 'moment';
  Vue.prototype.moment = moment
  moment.locale("cs");
  import {Table, TableColumn, Select, Option, Tabs, TabPane, Input, InputNumber, Tag, Popover, Tooltip, Button, ButtonGroup } from 'element-ui';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import Loading from 'src/components/Layout/LoadingMainPanel.vue';
  import Exams from 'src/components/Views/Exams.vue';
  import Vars from 'src/components/Views/Vars.vue';
  import PatientCard from 'src/components/Views/PatientCard.vue';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);

  export default {
    name: 'patients',
    props: {
    },  
    components: {
      Exams,
      Vars,
      PPagination,
      [PatientCard.name]: PatientCard,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Option.name]: Option,
      [Select.name]: Select,
      [Input.name]: Input,
      [InputNumber.name]: InputNumber,
      [Tag.name]: Tag,
      [Popover.name]: Popover,
      [Tooltip.name]: Tooltip,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
    },

    data() {
      return {      
        pagination_pat: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0
        },

      }
    },

    computed: {
      ...mapState({
        patients: state => state.patients,
        id_patient: state => state.id_patient,
        id_examination: state => state.id_examination,
        user: state => state.user,
      }),

    ...mapGetters({

      }),


      pagedData_pat () {
        return this.patients.slice(this.from_pat, this.to_pat)
      },

      patients_data () {
        if (!this.searchQuery_pat) {
          this.pagination_pat.total = this.patients.length
          return this.pagedData_pat
        }
        let result = this.patients
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_pat) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_pat)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_pat.total = result.length
        return result.slice(this.from_pat, this.to_pat)
      },

      to_pat () {
        let highBound = this.from_pat + this.pagination_pat.perPage
        if (this.total_pat < highBound) {
          highBound = this.total_pat
        }
        return highBound
      },

      from_pat () {
        return this.pagination_pat.perPage * (this.pagination_pat.currentPage - 1)
      },

      total_pat () {
        this.pagination_pat.total = this.patients.length
        return this.patients.length
      },

    },

    methods: {
      handleLike (index, row, event) {
        event.stopImmediatePropagation();
      },

      tableRowClassName({row, rowIndex}) {
        console.log(row.status);
        if (row.status === "0") {
          return 'row_0';
        } else if (row.status === "1") {
          return 'row_1';
        } else if (row.status === "2") {
          return 'row_2';
        } else if (row.status === "3") {
          return 'row_3';
        } else if (row.status === "4") {
          return 'row_4';
        } else {
          return 'row_9';
        }
      },

      submit_np(){
        console.log(this.formdata_np);
      },

      change_patient_page(){
        console.log(this.var_page);
      },

      show_tab_click(tab, event){
        console.log(tab.index);
      },


      show_click(value){
        console.log(value);
      },

      show_patients(){
        this.a_patients();
      },

      // cufi_genes_del(){
      //     let mykey = "genes";
      //     let value = [];
      //     this.$store.commit('m_cufi', {mykey, value});
      // },
    
      // mylink_symbol(myvar) {
      //   return "https://www.genecards.org/cgi-bin/carddisp.pl?gene=" + myvar ;
      // },



      ...mapMutations({
        m_id_patient: 'm_id_patient',
        m_id_examination: 'm_id_examination',
        m_id_project: 'm_id_project',
        m_vars: 'm_vars',
      }),


      ...mapActions({
        a_patients: 'a_patients',
        a_examinations: 'a_examinations',
        a_notify: 'a_notify',

      }),

      // setCurrent(row) {
      //   this.$refs.singleTable.setCurrentRow(row);
      // },
      handleCurrentChange_pat(val) {
        if (val && val.id_patient > 0) {
          this.m_id_project(0);
          this.m_id_patient(val.id_patient);
          this.m_id_examination(0);
          this.m_vars([]);
          // this.m_pan_project(true);
          
          // this.a_examinations( {"id_patient": val.id_patient} );
          // this.project_name = val.name;
        } else {
          this.m_id_project(0);
          this.m_id_examination(0);
          // this.m_pan_project(false);
          // this.project_name = "";
        }
      },



    },

    beforeMount () {
      this.m_id_patient(0);
      this.m_id_examination(0);
      this.m_id_project(0);
      this.a_patients();
      moment.locale("us");
      // this.formdata_np.created_dt = moment(Date().toLocaleString()).format('LLL');
    },



  }
</script>
<style scoped>
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}

.dolu {
  padding-top: 80px !important;
}

.space_buttons {
  margin-right: 5px !important;
}




</style>
