<template>
  <div class="dolu">
    <patients></patients>
    <!-- <exams v-if="id_patient > 0"></exams> -->
    <!-- <vars v-if="id_examination > 0"></vars> -->
    <patient-card v-if="id_patient > 0" got_id_patient="id_patient"></patient-card>
  </div>
</template>
<script>
  import Patients from 'src/components/Views/Patients.vue';
  import PatientCard from 'src/components/Views/PatientCard.vue';
  import Exams from 'src/components/Views/Exams.vue';
  import Vars from 'src/components/Views/Vars.vue';


  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';

  export default {
    components: {
      [Patients.name]: Patients,
      [Exams.name]: Exams,
      [Vars.name]: Vars,
      [PatientCard.name]: PatientCard,
    },
    computed: {
      ...mapState({
        patients: state => state.patients,
        id_patient: state => state.id_patient,
        id_patient_card: state => state.id_patient_card,
        // id_project_detail: state => state.id_project_detail,
        // examinations: state => state.examinations,
        id_examination: state => state.id_examination,
        // vars: state => state.vars,
        // id_var: state => state.id_var,
        // workflows: state => state.workflows,

        user: state => state.user,
        token: state => state.token,

      }),

      modal_new_patient: {
        get () {
          return this.$store.state.modal_new_patient;
        },
        set (value) {
          this.$store.commit('m_modal_new_patient', value);
        }
      },


    },


    data () {
      return {
        formdata_np: {
          name: "",
          np_id_workflow: "",
          description: "",
        },

        formdata_ep: {
          id_project: 0,
          name: "",
          description: "",
        },

        formdata_patients: {
          metadata: "",

        },


        pagination_p: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery_p: '',
        propsToSearch_p: ['name', ],
        tableColumns_p: [
          {
            prop: 'id_project',
            label: 'ID',
            minWidth: 30
          },
          {
            prop: 'name',
            label: 'Project Name',
            minWidth: 120
          },
          {
            prop: 'workflow',
            label: 'Workflow',
            minWidth: 80
          },          {
            prop: 'id_status',
            label: 'Status',
            minWidth: 30
          },
          {
            prop: 'description',
            label: 'Description',
            minWidth: 200
          },

        ],

        pagination_e: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        tableColumns_e: [
          {
            prop: 'id_examination',
            label: 'ID',
            minWidth: 40,
            align: "right"
          },
          {
            prop: 'id_patient',
            label: 'Patient',
            minWidth: 60
          },
          {
            prop: 'status',
            label: 'Status',
            minWidth: 60
          },
          {
            prop: 'description',
            label: 'Description',
            minWidth: 300
          },

        ],

        pagination_v: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50, 100, 200, 500, 1000],
          total: 0
        },
        tableColumns_v: [
          {
            prop: 'id_var',
            label: 'ID',
            minWidth: 60,
            align: "right",
            sortable: false,
          },
          {
            prop: 'pos',
            label: 'POS',
            minWidth: 70,
            align: "right",
            sortable: false,
          },
          {
            prop: 'chrom',
            label: 'CHROM',
            minWidth: 60,
            align: "right",
            sortable: false,
          },
          {
            prop: 'ref',
            label: 'REF',
            minWidth: 80,
            align: "center",
            sortable: false,
          },
          {
            prop: 'alt',
            label: 'ALT',
            minWidth: 80,
            align: "center",
            sortable: false,
          },
          {
            prop: 'gt',
            label: 'GT',
            minWidth: 40,
            align: "center",
            sortable: false,
          },
          {
            prop: 'gq',
            label: 'GQ',
            minWidth: 40,
            align: "right",
            sortable: false,
          },
          {
            prop: 'sdp',
            label: 'SDP',
            minWidth: 40,
            align: "right",
            sortable: false,
          },
          {
            prop: 'dp',
            label: 'DP',
            minWidth: 40,
            align: "right",
            sortable: false,
          },
          {
            prop: 'rd',
            label: 'RD',
            minWidth: 40,
            align: "right",
            sortable: false,
          },
          {
            prop: 'ad',
            label: 'AD',
            minWidth: 40,
            align: "right",
            sortable: false,
          },
          {
            prop: 'freq',
            label: 'FREQ',
            minWidth: 90,
            align: "right",
            sortable: true,
          },
          {
            prop: 'description',
            label: 'DESCR.',
            minWidth: 200,
            align: "left",
            sortable: false,
          },
        ],

        project_name: "", 

      }
    },

    methods: {
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },

      handle_project_detail (index, row, event) {
        event.stopImmediatePropagation();
        this.formdata_ep.id_project = row.id_project;
        this.formdata_ep.name = row.name;
        this.formdata_ep.description = row.description;
        this.m_id_project_detail(row.id_project);
        this.files = [];
        this.other_files = [];
        this.csv_file = [];
        this.m_modal_project_detail(true);
      },

      handle_project_stat (index, row, event) {
        event.stopImmediatePropagation();
        console.log(row.id_project);
        // this.formdata_ep.id_project = row.id_project;
        // this.formdata_ep.name = row.name;
        // this.formdata_ep.description = row.description;
        this.m_id_project_detail(row.id_project);
        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        this.m_modal_project_stat(true);

      },

      tableRowClassName({row, rowIndex}) {
        if (row.id_status === 0) {
          return 'row_0';
        } else if (row.id_status === 1) {
          return 'row_1';
        } else if (row.id_status === 2) {
          return 'row_2';
        } else if (row.id_status === 3) {
          return 'row_3';
        } else if (row.id_status === 4) {
          return 'row_4';
        } else {
          return 'row_9';
        }
      },

      handleShowQC (index, row, event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        // this.m_id_project_detail(row.id_project);

        this.a_show_qc( { "id_project": row.id_project } );
        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        // this.m_modal_project_stat(true);
      },


      submit_np(){
        this.a_new_project(this.formdata_np)
        this.m_modal_new_project(false);
      },

      submit_ep(){
        this.a_project_update(this.formdata_ep)
      },

      add_patient(){
        // moment.locale("us");
        // this.formdata_np.name = "";
        // this.formdata_np.np_id_workflow = "";
        // this.formdata_np.description = "";

        // this.formdata_np.created_dt = moment(Date().toLocaleString()).format('LLL');

        // this.m_modal_new_project(true);
        // this.m_id_project(0);
      },

      project_detail(){
        console.log("HELLO!?!");

      },


      ...mapMutations({
        m_id_project: 'm_id_project',
        m_id_project_detail: 'm_id_project_detail',
        m_id_examination: 'm_id_examination',
        m_id_var: 'm_id_var',
        m_np_id_workflow: 'm_np_id_workflow',
        m_modal_new_project: 'm_modal_new_project',
        m_modal_project_detail: 'm_modal_project_detail',
        m_modal_project_stat: 'm_modal_project_stat',
        m_id_patient: 'm_id_patient',
        m_id_patient_card: 'm_id_patient_card',
        m_vars: 'm_vars',
      }),


      ...mapActions({
        a_projects: 'a_projects',
        a_examinations: 'a_examinations',
        a_vars: 'a_vars',
        a_workflows: 'a_workflows',
        a_new_project: 'a_new_project',
        a_project_update: 'a_project_update',
        a_show_qc: 'a_show_qc',

        }),

      // setCurrent(row) {
      //   this.$refs.singleTable.setCurrentRow(row);
      // },
      handleCurrentChange_p(val) {
        if (val && val.id_project > 0) {
          this.m_id_project(val.id_project);
          this.m_id_examination(0);
          this.m_vars([]);
          this.a_examinations( {"id_project": val.id_project} );
          this.project_name = val.name;
        } else {
          this.m_id_project(0);
          this.m_id_examination(0);
          this.project_name = "";
        }
      },

      handleCurrentChange_e(val) {
        if (val && val.id_examination > 0) {
          this.m_id_examination(val.id_examination);
          this.a_vars( {"order": 1, "id_examination": val.id_examination} );
        } else {
          this.m_id_examination(0);
        }
      },

      handleCurrentChange_v(val) {
        if (val && val.id_var > 0) {
          // this.a_examinations(val.id_examination);
        } else {
          // this.m_i_examination(0);
        }
      },

      inputFile: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
      },

      inputFilecsv: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
      },

      inputFileOther: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          console.log('response', newFile.response)
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
      },


    },

    beforeMount () {
      this.a_projects();
      this.a_workflows();
      this.m_id_patient(0);
      this.m_id_examination(0);
      this.m_id_project(0);
    },

  }
</script>
<style scoped lang="scss">
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}

.el-table {
  font-size: 12px !important;
}

.el-table .cell{
  line-height: 20px !important;
}

.el-table td, .el-table th {
  padding: 3px 0 !important;
}

.el-table .row_0 {
  background: rgb(253, 253, 253) !important;
}

.el-table .row_1 {
  background: rgb(243, 245, 152) !important;
}

.el-table .row_2 {
  background: rgb(248, 221, 171) !important;
}

.el-table .row_3 {
  background: rgb(248, 186, 157) !important;
}

.el-table .row_4 {
  background: rgb(197, 248, 208) !important;
}

.el-table .row_9 {
  background: rgb(250, 197, 184) !important;
}



.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #0ada14 !important;
}

.el-table .info-row {
  background: #8fbdf1 !important;
}

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}

.table > thead > tr > th, .el-table table > thead > tr > th {
  font-size: 12px !important;
}

.mezera {
  margin-right: 10px;
}
</style>
