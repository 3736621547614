<template>
  <div class="wrapper">
    <notifications></notifications>

    <!-- <div class="main-panel"> -->
    <div v-if="token > '' && application === 'GENOVESA'" class="main-panel-token main_background">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>

    <div v-else-if="token > '' && (application === 'NGS-GENOVESA' || application === 'NGS-GENOVESA-DEMO')" class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>

    <div v-else class="main-panel main_background">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>


    <el-dialog :visible.sync="modal_login" title=" Sign-In " headerClasses="justify-content-center" :show-close="true" width="30%">
      <div class="ml-auto mr-auto">
        <form @submit.prevent="a_login({email:form.email,password:form.password})">
          <card type="login">
            <h3 slot="header" class="header text-center"> </h3>
            <el-input v-model="form.email" prefix-icon="nc-icon nc-single-02" placeholder="e-mail is your username..."></el-input>
            <el-input v-model="form.password" prefix-icon="nc-icon nc-key-25" placeholder="Password" type="password"></el-input>
            <el-divider></el-divider>
            <div class="row">
              <el-button native-type="submit" @click="m_modal_reg(false)" class="my_butt_1"> OK </el-button>
            </div>
            <div class="row text-center" style="margin-top: 20px">
              <el-button type="text"  @click="m_modal_login(false); m_modal_forgot(true)"> Forgot your password? </el-button>
            </div>
          </card>

        </form>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="modal_forgot" title=" Forgotten password " headerClasses="justify-content-center" :show-close="true" width="30%">
      <div class="ml-auto mr-auto">
        <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="a_forgot({ email:form_forgot.email, application: application })">
          <card type="login">
            <h3 slot="header" class="header text-center"> </h3>
              <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
                <el-input v-model="form_forgot.email" prefix-icon="nc-icon nc-single-02" placeholder="your email"></el-input>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
              <br>
            <div class="row" style="margin-top: 20px">
              <el-button native-type="submit" @click="m_modal_forgot(false)" class="my_butt_1"> OK </el-button>
            </div>
          </card>
        </form>
        </ValidationObserver>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="modal_passwd" title=" Change password " headerClasses="justify-content-center" :show-close="true" width="30%">
      <div class="ml-auto mr-auto">
        <ValidationObserver ref="observer_passwd" v-slot="{ invalid, valid, validate }">
          <el-form>
            <card type="login">
              <h3 slot="header" class="header text-center"> Choose a strong password </h3>
                <ValidationProvider name="Passwords" rules="required|confirmed:confirm" v-slot="{ errors }">
                  <el-input v-model="form_passwd.passwd1" prefix-icon="nc-icon nc-single-02" placeholder="New password " show-password></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="Passwords" rules="required" vid="confirm">
                  <el-input v-model="form_passwd.passwd2" prefix-icon="nc-icon nc-single-02" placeholder="Confirm new password " show-password></el-input>
                </ValidationProvider>
                <br>
              <div class="row">
                <el-button native-type="submit" @click="onSubmit_passwd($event)" class="my_butt_2"> OK </el-button>
              </div>
            </card>
          </el-form>
        </ValidationObserver>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="modal_reg" title="Registration of a new customer for:" headerClasses="justify-content-center" :show-close="true" width="60%">
      <div class="ml-auto mr-auto">
        <ValidationObserver v-slot="{ invalid }">
        <el-form class="form-horizontal">
          <card type="signup" class="text-center gray_bg">
            <el-row>
              <el-divider content-position="center">(At least one module is required)</el-divider>
              <el-col :span="12">
                <el-checkbox v-model="form_reg.fastgen" label="fastGEN" border style="width:80%"></el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-checkbox v-model="form_reg.vlassay" label="ViennaLab NGS Assay" border style="width:80%"></el-checkbox>
              </el-col>
            </el-row>
            <el-divider></el-divider>

            <el-row>
              <el-col :span="12">
                <div class="grid-content">
                  <h4> INFO ABOUT ORGANIZATION </h4>
                </div>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="1">
                    <div class="grid-content">
                    </div>
                  </el-col>
                  <el-col :span="22">
                    <div class="grid-content">
                      <el-row>
                        <el-col :span="24">
                          <div class="grid-content">
                          <label> <i class="el-icon-star-on"></i> Organization name </label>
                            <el-input v-model="form_reg.subject" placeholder=""></el-input>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="grid-content">
                          <label> Street Address </label>
                            <el-input v-model="form_reg.street" placeholder=""></el-input>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="grid-content">
                          <label> City </label>
                            <el-input v-model="form_reg.city" placeholder=""></el-input>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="grid-content">
                          <label> ZIP code </label>
                            <el-input v-model="form_reg.zip" placeholder=""></el-input>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="grid-content">
                          <label> Country </label>
                            <el-input v-model="form_reg.country" placeholder=""></el-input>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="1">
                    <div class="grid-content">
                    </div>
                  </el-col>
                </el-row>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <h4> INFO ABOUT USERS </h4>
                </div>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="1">
                    <div class="grid-content">
                    </div>
                  </el-col>
                  <el-col :span="22">
                    <div class="grid-content">
                      <el-row>
                        <el-col :span="24">
                          <el-collapse v-model="elcol">
                            <el-collapse-item title="First free account" name="1">
                              <div class="grid-content">
                              <label> <i class="el-icon-star-on"></i> E-mail of user 1 </label>
                              <el-input v-model="form_reg.user_1_email" placeholder=""></el-input>
                              </div>
                              <div class="grid-content">
                              <label> First name of user 1</label>
                              <el-input v-model="form_reg.user_1_name" placeholder=""></el-input>
                              </div>
                              <div class="grid-content">
                              <label> Last name of user 1</label>
                              <el-input v-model="form_reg.user_1_surname" placeholder=""></el-input>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item title="Second free account" name="2">
                              <div class="grid-content">
                              <label> E-mail of user 2</label>
                              <el-input v-model="form_reg.user_2_email" placeholder=""></el-input>
                              </div>
                              <div class="grid-content">
                              <label> First name of user 2</label>
                              <el-input v-model="form_reg.user_2_name" placeholder=""></el-input>
                              </div>
                              <div class="grid-content">
                              <label> Last name of user 2</label>
                              <el-input v-model="form_reg.user_2_surname" placeholder=""></el-input>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item title="Third free account" name="3">
                              <div class="grid-content">
                              <label> E-mail of user 3</label>
                              <el-input v-model="form_reg.user_3_email" placeholder=""></el-input>
                              </div>
                              <div class="grid-content">
                              <label> First name of user 3</label>
                              <el-input v-model="form_reg.user_3_name" placeholder=""></el-input>
                              </div>
                              <div class="grid-content">
                              <label> Last name of user 3</label>
                              <el-input v-model="form_reg.user_3_surname" placeholder=""></el-input>
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="1">
                    <div class="grid-content">
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-checkbox v-show="!(token > '')" class="text-left" v-model="form_reg.acceptTerms">
              <a v-show="!(token > '')" @click="m_modal_terms(true)" class="logo" title="We bring to the market diagnostic tools for innovative methods of molecular diagnostics from BioVendor Group laboratories - NGS, LAMP and miRNA.">
                <span> Confirm acceptance of all Terms and Conditions </span>
              </a>
            </el-checkbox>

            <el-row>
              <el-col :span="4">
                <div class="grid-content dolu">
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content dolu">
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content dolu">
                  <el-button :disabled="!form_reg.acceptTerms || (!form_reg.fastgen && !form_reg.vlassay) || form_reg.subject === '' || form_reg.user_1_email === ''" @click="onSubmit_reg($event)" class="my_butt_1"> OK </el-button>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content dolu">
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content dolu">
                </div>
              </el-col>

              <el-col :span="24">
                <div class="grid-content dolu">
                </div>
              </el-col>
            </el-row>

          </card>
        </el-form>
        </ValidationObserver>
      </div>
    </el-dialog>


    <modal :show.sync="modal_info" headerClasses="justify-content-center" :show-close="false">
              <div slot="header" class="modal-profile d-flex justify-content-center align-items-center">
                <i class="fas fa-times-circle"></i>
              </div>
                  <p>{{ modal_info_text }}</p>
                  <template slot="footer">
                    <p-button type="submit" link @click.native="m_modal_info(false)">Close</p-button>
                  </template>
    </modal>


    <el-dialog :visible.sync="modal_terms" title=" Terms and Conditions " headerClasses="justify-content-center" :show-close="true" width="80%">
      <div class="ml-auto mr-auto" v-html="terms_and_conditions">

      </div>
    </el-dialog>


  </div>

</template>

<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import Modal from '../UIComponents/Modal'
  import Button from '../UIComponents/Button'
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  // import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import axios from 'axios';
  import VueNotify from 'vue-notifyjs'

  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email, confirmed, min } from 'vee-validate/dist/rules';

  extend('required', {
      ...required,
      message: 'This field is required!'
  });

  extend('email', {
      ...email,
      message: 'Invalid e-mail pattern.'
  });

  extend('min', {
      ...min,
      message: 'At least 6 chars is required.'
   });

  extend('confirmed', {
      ...confirmed,
      message: 'Passwords are different.'
  });

  export default {
    data(){
        return {
          form: {
            email: "",
            password: "",
          },
          form_reg: {
            subject: "",
            street: "",
            city: "",
            zip: "",
            country: "",
            acceptTerms: true,
            user_1_email: "",
            user_1_name: "",
            user_1_surname: "",
            user_2_email: "",
            user_2_name: "",
            user_2_surname: "",
            user_3_email: "",
            user_3_name: "",
            user_3_surname: "",
            fastgen: false,
            vlassay: false,
          },
          form_reg_valid: false,
          form_forgot: {
            email: "",
          },
          form_passwd: {
            passwd1: "",
            passwd2: "",
          },

          elcol: "1",

        }
      },

    // watch: {
    //   subject: {
    //     handler: 'test_watch',
    //     immediate: true
    //   },
    // },


    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      Modal,
      ValidationProvider,
      ValidationObserver,
      Navbar,
      NavbarToggleButton,
      // UserMenu,
    },

    computed: {
      ...mapState({
        // modal_forgot: state => state.modal_forgot,
        modal_info: state => state.modal_info,
        modal_info_text: state => state.modal_info_text,

        email: state => state.email,
        token: state => state.token,
        application: state => state.application,
        subject: state => state.subject,
        ws: state => state.ws,
        terms_and_conditions: state => state.terms_and_conditions,
      }),

      ...mapGetters({

      }),

      modal_reg: {
        get () { return this.$store.state.modal_reg; },
        set (value) { this.$store.commit('m_modal_reg', value); }
      },

      modal_login: {
        get () { return this.$store.state.modal_login; },
        set (value) { this.$store.commit('m_modal_login', value); }
      },

      modal_forgot: {
        get () { return this.$store.state.modal_forgot; },
        set (value) { this.$store.commit('m_modal_forgot', value); }
      },

      modal_passwd: {
        get () { return this.$store.state.modal_passwd; },
        set (value) { this.$store.commit('m_modal_passwd', value); }
      },

      modal_terms: {
        get () { return this.$store.state.modal_terms; },
        set (value) { this.$store.commit('m_modal_terms', value); }
      },

    },

    methods: {
      ...mapMutations({
        m_modal_login: 'm_modal_login',
        m_modal_reg: 'm_modal_reg',
        m_modal_forgot: 'm_modal_forgot',
        m_modal_passwd: 'm_modal_passwd',
        m_modal_info: 'm_modal_info',
        m_modal_info_text: 'm_modal_info_text',
        m_modal_terms: 'm_modal_terms',

        m_login: 'm_login',
        m_ws: 'm_ws',

      }),

      // test_watch () {
      //   if (this.subject.id_subject === 0 && this.token > "") {
      //     this.a_load_profile();

      //   } else {
      //     console.log("BBBB");
      //   }
      // },

      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },

      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },

      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },




      login () {
        // console.log(this.form.email);
        // console.log(this.form.password);
        // this.m_login(this.form.email, this.form.password);
        // this.form.email = ""
        // this.form.password = ""
        // this.m_modal_login(false);
      },

      ...mapActions({
        a_login: 'a_login',
        a_forgot: 'a_forgot',
        a_passwd: 'a_passwd',
        a_reg: 'a_reg',
        a_load_profile: 'a_load_profile',

      }),


      onSubmit_reg (event) {
        event.preventDefault();
        this.a_reg({
          subject : this.form_reg.subject,
          street : this.form_reg.street,
          city : this.form_reg.city,
          zip : this.form_reg.zip,
          country : this.form_reg.country,
          acceptTerms : this.form_reg.acceptTerms,
          user_1_email : this.form_reg.user_1_email,
          user_1_name : this.form_reg.user_1_name,
          user_1_surname : this.form_reg.user_1_surname,
          user_2_email : this.form_reg.user_2_email,
          user_2_name : this.form_reg.user_2_name,
          user_2_surname : this.form_reg.user_2_surname,
          user_3_email : this.form_reg.user_3_email,
          user_3_name : this.form_reg.user_3_name,
          user_3_surname : this.form_reg.user_3_surname,
        });
      },


      onSubmit_passwd (event) {
        event.preventDefault();
        this.$refs.observer_passwd.validate()
          .then((result) => {
            let isValid = result;
              console.log(result);
              if (result) {
                this.a_passwd({
                  passwd1     : this.form_passwd.passwd1,
                  passwd2     : this.form_passwd.passwd2,
                });
              } else{
                this.$alert('Zadané údaje nejsou validní, opravte je.', 'POZOR!', {
                  confirmButtonText: 'OK',
                });
              }
          })
          .catch((error) => {
              console.log(error);
          });



      },


    },


    created () {
      // console.log("Starting connection to WebSocket Server")
      // var ws = new WebSocket("wss://localhost:8101")
      // this.m_ws(ws)

      // this.ws.onmessage = function(event) {
      //   console.log(event);
      // }

      // this.ws.onopen = function(event) {
      //   console.log(event)
      //   console.log("Successfully connected to the echo websocket server...")
      // }
    },





  }


</script>

<style lang="scss">
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.main-panel {
  background-color: #C6CCD3 !important;
}

.gray_bg {
  background-color: #C6CCD3 !important;
}

.main-panel > .content {
    min-height: calc(100vh - 200px) !important;
}

.main-panel-token > .content {
    min-height: calc(100vh - 50px) !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.modal-dialog {
    padding-top: 100px !important;
    max-width: 1200px !important;
    min-height: 400px !important;
}

.modal {
  // z-index: 5999 !important;
}

.main_background {
    min-height: calc(100vh - 0px) !important;
}

.el-table {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: black !important;
}

.table > thead > tr > th, .el-table table > thead > tr > th {
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 0;
    border: 0;
}

.el-table .row_0 {
  background: #fafafa !important;
}


.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #a7ffb9 !important;
}

.el-table .danger-row {
  background: #f34c19 !important;
}

.el-table .info-row {
  background: #ffe5cc !important;
}

.el-table .info-row .el-tabs--border-card {
  background: #ffd7c1 !important;
}

.sort-caret {
  font-size: 24px !important;
}

.my_butt_1 {
  background-color: #1C3552 !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 700;
  width: 100%;
  height: 60px;
}

.my_butt_2 {
  background-color: #1C3552 !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 700;
  width: 100%;
  height: 60px;
  margin-top: 20px !important;
}

.my_butt_close {
  background-color: white !important;
  color: #1C3552 !important;
  font-size: 22px !important;
  font-weight: 700;
  width: 100%;
  height: 60px;
}


.el-collapse-item__header {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

// label {
//   padding-top: 20px !important;
// }

.my_label {
  text-align: left !important;
}

// .el-input {
//   margin-bottom: 20px !important;
// }

.el-divider__text {
  background-color: #c6ccd3 !important;
}

</style>
