<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div v-if="id_project" class="col-sm-3 title"> Samples of project {{ g_project.name }} </div>
        <div v-else class="col-sm-3"> </div>

        <!-- <div class="col-sm-2 ml-auto mr-auto">
          <el-tooltip v-show="true" content="Select type of visualisation" placement="top" effect="light" :open-delay="300">
            <el-switch
              style="display: block"
              v-model="exam_visualisation"
              @change="exam_visualisation_change"
              active-text="By Exam"
              inactive-text="By Group"
              >
            </el-switch>
          </el-tooltip>
        </div>

        <div class="col-sm-5 ml-auto mr-auto">
          <el-select class="select-info"
                    placeholder="Select Samples"
                    multiple
                    size="small"
                    style="width: 90%"
                    v-model="xxxxxxxxx"
                    >
            <el-option v-for="option in examinations_data"
                      class="select-info"
                      :value="option.id_examination"
                      :label="option.sample_id"
                      :key="option.id_examination"
                      >
            </el-option>
          </el-select>
        </div> -->

        <div class="col-sm-9">
          <div class="pull-right">
            <el-input size="medium"
                      placeholder="Search"
                      v-model="searchQuery_e"
                      suffix-icon="icofont-search-2">
            </el-input>
          </div>
        </div>

        <div class="col-sm-12 mt-2">
          <el-table class=""
            ref="examinations"
            :data="examinations_data"
            border
            v-loading="loading_e"
            element-loading-text="Loading..."
            element-loading-spinner="icofont-refresh icofont-5x"
            element-loading-background="rgba(100, 100, 100, 0.5)"
            highlight-current-row
            :default-sort="{prop: 'id_examination', order: 'ascending'}"
            @current-change="handleCurrentChange"
            @selection-change="handleSelectionChange"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <template slot="empty">
                <div v-if="loading_e">
                </div>
                <div v-else style="font-size: 20px; font-weight: 800; text-align: left">
                  No examinations found
                </div>
            </template>
            <el-table-column
              prop="id_examination"
              label="ID"
              min-width="50"
              align="left"
              sortable
              >
            </el-table-column>

            <el-table-column
              type="selection"
              width="55"
              >
            </el-table-column>

            <el-table-column
              prop="sample_id"
              label="SampleID"
              min-width="120"
              align="left"
              sortable
              >
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.data_quality !== '' && scope.row.status > 9" placement="top" effect="light" :open-delay="200">
                  <div slot="content" v-html="scope.row.data_quality"></div>
                  <el-tag size="mini" effect="dark" type="warning"><i class="icofont-exclamation-square"></i></el-tag>
                </el-tooltip>
                <el-tooltip v-if="scope.row.data_quality === '' && scope.row.status > 9" content="Quality status is OK." placement="top" effect="light" :open-delay="200">
                  <el-tag size="mini" effect="dark" type="success"><i class="icofont-check"></i></el-tag>
                </el-tooltip>

                {{ scope.row.sample_id }}
              </template>
            </el-table-column>

            <el-table-column v-if="false"
              prop="sample_name"
              label="SampleName"
              min-width="100"
              align="left"
              sortable
              >
            </el-table-column>

            <el-table-column v-if="false"
              label="Patient"
              min-width="150"
              align="center"
              >
              <template v-if="scope.row.hash > ''" slot-scope="scope">
                  <el-tag size="small" type="success" effect="plain" @click="handlePatient(scope.row, $event)">{{ scope.row.patname }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column
              label="Status"
              min-width="70"
              align="center"
              prop="status"
              sortable
              >
              <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 0" size="small" effect="plain"> NEW </el-tag>
                  <el-tag v-else-if="scope.row.status === 1" size="small" effect="dark"> UPLOADED </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" size="small" type="danger" effect="dark"> WRONG </el-tag>
                  <el-tag v-else-if="scope.row.status === 3" size="small" type="warning" effect="plain"> SUBMITTED </el-tag>
                  <el-tag v-else-if="scope.row.status === 4" size="small" type="warning" effect="dark"> RUNNING </el-tag>
                  <el-tag v-else-if="scope.row.status === 8" size="small" type="warning" effect="plain"> RUNNING </el-tag>
                  <el-tag v-else-if="scope.row.status === 9" size="small" type="warning" effect="dark"> RUNNING </el-tag>
                  <el-tag v-else-if="scope.row.status === 10" size="small" type="success" effect="plain"> FINISHED </el-tag>
                  <el-tag v-else-if="scope.row.status === 11" size="small" type="success" effect="dark"> FINISHED </el-tag>
                  <el-tag v-else-if="scope.row.status === 12" size="small" type="info" effect="dark"> PASSED </el-tag>
                  <el-tag v-else-if="scope.row.status === 15" size="small" type="default" effect="dark"> SIGNED OUT </el-tag>
                  <el-tag v-else-if="scope.row.status === -5" size="small" type="danger" effect="dark"> MISSING DATA </el-tag>
                  <el-tag v-else size="small" type="default" effect="plain">  </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp === 'genetify'"
              prop="input_reads"
              label="Input reads"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.input_reads" slot-scope="scope">
                {{ scope.row.input_reads.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp === 'genetify'"
              prop="reads_after_analysis"
              label="Reads after"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.reads_after_analysis" slot-scope="scope">
                {{ scope.row.reads_after_analysis.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp === 'genetify'"
              prop="total_loss"
              label="Total loss"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.total_loss" slot-scope="scope">
                {{ scope.row.total_loss.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp === 'genetify'"
              prop="shannon"
              label="Shannon"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.shannon" slot-scope="scope">
                {{ scope.row.shannon.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp === 'genetify'"
              prop="fb_ratio"
              label="FB ratio"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.fb_ratio" slot-scope="scope">
                {{ scope.row.fb_ratio.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp === 'genetify'"
              prop="enterotype"
              label="Enterotype"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.enterotype" slot-scope="scope">
                {{ scope.row.enterotype.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp !== 'genetify'"
              prop="total_reads"
              label="Total reads"
              min-width="80"
              align="center"
              sortable
              >
              <template v-if="scope.row.total_reads" slot-scope="scope">
                {{ scope.row.total_reads.toLocaleString('cs-CZ') }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="rapp !== 'genetify'"
              prop="varcount"
              label="Total Variants"
              min-width="60"
              align="center"
              >
            </el-table-column>

            <el-table-column
              v-if="rapp !== 'genetify'"
              prop="median_target_coverage"
              label="Median depth"
              min-width="75"
              align="center"
              sortable
              >
            </el-table-column>

            <el-table-column
              v-if="rapp !== 'fastras' && rapp !== 'genetify'"
              prop="pct_exc_dupe"
              label="% Duplicates"
              min-width="75"
              align="center"
              sortable
              >
            </el-table-column>

            <el-table-column
              v-if="rapp !== 'genetify'"
              prop="pct_target_bases_30x"
              label="% Depth 30X"
              min-width="70"
              align="center"
              sortable
              >
            </el-table-column>

            <el-table-column
              v-if="rapp !== 'genetify'"
              prop="pct_target_bases_100x"
              label="% Depth 100X"
              min-width="70"
              align="center"
              sortable
              >
            </el-table-column>

            <el-table-column v-if="(rapp !== 'fastras') && false"
              prop="statim"
              label="Statim"
              min-width="50"
              align="center"
              sortable
              >
              <template v-if="scope.row.statim" slot-scope="scope">
                <el-tag size="small" type="danger" effect="plain"> YES </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              :min-width="150"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">
                <el-tooltip v-if="rapp !== 'genetify' && id_biorole > 3" content="Manage files of examination" placement="top" effect="light" :open-delay="500">
                  <el-button type="primary" size="mini" @click="handle_exam_files(props.$index, props.row, $event)">
                    <i class="icofont-edit"></i>
                  </el-button>
                </el-tooltip>

                <el-tooltip v-if="rapp !== 'genetify' && id_biorole > 3" content="Manage data of examination" placement="top" effect="light" :open-delay="500">
                  <el-button type="primary" size="mini" @click="handle_exam_detail(props.$index, props.row, $event)">
                    <i class="icofont-tasks"></i>
                  </el-button>
                </el-tooltip>

                <!-- <el-tooltip content="Make report" placement="top" effect="light" :open-delay="500">
                  <el-button type="success" size="sm" @click="handle_make_report(props.$index, props.row, $event)">
                    <i class="icofont-patient-file"></i>
                  </el-button>
                </el-tooltip> -->
                <el-tooltip v-if="props.row.status > 0 && props.row.status === 2" content="Valide examination" placement="top" effect="light" :open-delay="500">
                  <el-button type="warning" size="mini" @click="handle_exam_validate(props.$index, props.row, $event, type='BP')">
                    <i class="icofont-check-circled"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="rapp !== 'genetify' && props.row.status > 9" content="Mapping QC" placement="top" effect="light" :open-delay="500">
                  <el-button type="info" size="mini" @click.prevent="handleModalQC( props.$index, props.row, $event, 'Q')">
                    <i class="icofont-chart-bar-graph"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="props.row.status > 9" content="FASTQ QC" placement="top" effect="light" :open-delay="500">
                  <el-button type="info" size="mini" @click.prevent="handleShowQC( props.$index, props.row, $event, 'M')">
                    <i class="icofont-chart-pie"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="(props.row.status > 9) && props.row.undercovered_reg" content="UNDERCOVERED REGION" placement="top" effect="light" :open-delay="500">
                  <el-button type="warning" size="mini" @click.prevent="handleShowUCR( props.$index, props.row, $event, 'M')">
                    <i class="icofont-align-center"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="props.row.status > 9 && props.row.msi_tmb" content="MSI and TMB" placement="top" effect="light" :open-delay="500">
                  <el-button type="info" size="mini" @click.prevent="handleModalMSI_TMB( props.$index, props.row, $event, 'M')">
                    <i class="icofont-pills"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="props.row.status > 9 && props.row.status < 12" content="Pass QC and show variants" placement="top" effect="light" :open-delay="500">
                  <el-button type="warning" size="mini" @click="handle_exam_validate(props.$index, props.row, $event, type='AP')">
                    <i class="icofont-checked"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="props.row.status < 3" content="Remove examination" placement="top" effect="light" :open-delay="500">
                  <el-button type="danger" size="mini" @click="handle_exam_delete(props.$index, props.row, $event)">
                    <i class="icofont-trash"></i>
                  </el-button>
                </el-tooltip>

                <el-tooltip v-if="props.row.status >11 && rapp === 'genetify' && subject.id_subject == 44" content="Print GENETIFY report" placement="top" effect="light" :open-delay="250">
                  <el-button type="success" size="mini" @click="print_gf_report(props.$index, props.row, $event)">
                    <i class="icofont-file-pdf"></i>
                  </el-button>
                </el-tooltip>

              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-sm-6 pagination-info">
        </div>
        <div class="col-sm-6">
            <el-pagination
              class="pull-right pagination_mezera"
              @size-change="handleSizeChangePage_e"
              @current-change="handleCurrentChangePage_e"
              :current-page.sync="pagination_e.currentPage"
              :page-sizes="pagination_e.perPageOptions"
              :page-size="pagination_e.perPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination_e.total"
              background
              >
            </el-pagination>
        </div>
      </div>
    </div>


    <el-dialog :visible.sync="modal_patient" width="80%" headerClasses="justify-content-center" :show-close="true" @close="handle_close_modal">
      <h5 slot="header" class="title title-up"> </h5>
      <div class="row">
        <div class="col-12 ml-auto mr-auto">
          <patient-card got_id_patient="id_patient">
          </patient-card>
        </div>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="modal_exam_detail" width="80%" headerClasses="justify-content-center" :show-close="true" :close-on-click-modal="false">
      <h5 v-if="g_examination" slot="header" class="title title-up">{{ this.g_examination.sample_id }}</h5>
      <div>
        <el-tabs class="col-md-12" type="card" @tab-click="">
          <el-tab-pane label="Sample details">
            <div class="ml-auto mr-auto box-card-modal-01">
              <el-row style="margin: 0px">
                <el-col :span="5" style="padding: 3px">
                  <label>NAME AND LAST NAME<span class="red_label"> (needed for report)</span></label>
                  <el-input v-model="exam_det_sample_name_and_last_name"></el-input>
                </el-col>
                <el-col :span="4" style="padding: 3px">
                  <label>BIRTH DATE<span class="red_label"> (needed for report)</span></label>
                  <el-date-picker
                    v-model="exam_det_birth_date"
                    type="date"
                    placeholder="YYYY-MM-DD">
                  </el-date-picker>
                </el-col>
                <el-col :span="4" style="padding: 3px; text-align: center">
                  <label>GENDER<span class="red_label"> (needed for report)</span></label>
                  <div>
                    <el-radio v-model="exam_det_gender" label="1">Female</el-radio>
                    <el-radio v-model="exam_det_gender" label="2">Male</el-radio>
                  </div>
                </el-col>
                <el-col :span="6" style="padding: 3px">
                  <label>REFERRING HOSPITAL</label>
                  <el-input v-model="exam_det_referring_hospital"></el-input>
                </el-col>
                <el-col :span="5" style="padding: 3px">
                  <label>ORDERING PHYSICIAN<span class="red_label"> (needed for report)</span></label>
                  <el-input v-model="exam_det_referred_by"></el-input>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Sample records">
            <div class="ml-auto mr-auto box-card-modal-01">
              <el-row style="margin: 0px">
                <el-col :span="7" style="padding: 3px">
                  <label>SAMPLE ID</label>
                  <el-input v-model="exam_det_sample_id"></el-input>
                </el-col>
                <el-col :span="7" style="padding: 3px">
                  <label>INTERNAL SAMPLE ID<span class="red_label"> (needed for report)</span></label>
                  <el-input v-model="exam_det_int_sample_id"></el-input>
                </el-col>
                <el-col :span="4" style="padding: 3px">
                  <label>PERSONAL ID</label>
                  <el-input v-model="exam_det_personal_id"></el-input>
                </el-col>
                <el-col :span="6" style="padding: 3px">
                  <label>SAMPLE TYPE<span class="red_label"> (needed for report)</span></label>
                  <el-input v-model="exam_det_sample_type"></el-input>
                </el-col>
                <el-col :span="4" style="padding: 3px" >
                  <label>SAMPLE TAKEN</label>
                 <el-date-picker
                    v-model="exam_det_sample_taken"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    >
                  </el-date-picker>
                </el-col>
                <el-col :span="4" style="padding: 3px">
                  <label>SAMPLE RECEIVED</label>
                  <el-date-picker
                    v-model="exam_det_sample_received"
                    type="date"
                    placeholder="YYYY-MM-DD">
                  </el-date-picker>
                </el-col>
                <el-col :span="6" style="padding: 3px">
                  <label v-if="this.exam_det.id_analysis_type === 2">INDICATION<span class="red_label"> (needed for report)</span></label>
                  <label v-else-if="this.exam_det.id_analysis_type === 1">CANCER TYPE<span class="red_label"> (needed for report)</span></label>
                  <label v-else>---</label>
                  <el-input v-model="exam_det_indication"></el-input>
                </el-col>
                <el-col :span="10" style="padding: 3px">
                </el-col>
              </el-row>

              <el-row style="margin: 0px">
                <el-col :span="12" style="padding: 3px;">
                  <label>HUMAN PHENOTYPE ONTOLOGY (HPO)</label>
                  <fg-input>
                    <el-select
                      v-model="exam_det_hpo"
                      multiple
                      :filterable="true"
                      :filter-method="hpo_filter"
                      :clearable="true"
                      reserve-keyword
                      placeholder="Start typing at least three characters ..."
                      >
                      <el-option
                        v-for="item in g_exam_hpo(exam_det_hpo, hpo_query)"
                        :key="item.hpo_id"
                        :label="item.hpo_term_name"
                        :value="item.hpo_id">
                      </el-option>
                    </el-select>
                  </fg-input>
                </el-col>

                <el-col :span="12" style="padding: 3px;">
                  <label>HPO NOTE</label>
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="exam_det_hpo_note">
                  </el-input>
                </el-col>
              </el-row>

              <el-row style="margin: 0px">
                <el-col :span="12" style="padding: 3px;">
                  <label>INTERNATIONAL CLASSIFICATION OF DISEASES (ICD)</label>
                  <fg-input>
                    <el-select
                      v-model="exam_det_icd11"
                      multiple
                      :filterable="true"
                      :filter-method="icd11_filter"
                      :clearable="true"
                      reserve-keyword
                      placeholder="Start typing at least three characters ..."
                      >
                      <el-option
                        v-for="item in g_exam_icd11(exam_det_icd11, icd11_query)"
                        :key="item.id_icd11"
                        :label="item.icd11_search"
                        :value="item.id_icd11">
                      </el-option>
                    </el-select>
                  </fg-input>
                </el-col>

                <el-col :span="12" style="padding: 3px;">
                  <label>ICD NOTE</label>
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="exam_det_icd_note">
                  </el-input>
                </el-col>
              </el-row>

              <el-row style="margin: 0px">
                <el-col :span="12" style="padding: 3px;">
                  <label>INDICATION FOR TESTING<span class="red_label"> (needed for report)</span></label>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="exam_det_indication_for_testing">
                  </el-input>
                </el-col>
                <el-col :span="12" style="padding: 3px;">
                  <label>RESULT INTERPRETATION<span class="red_label"> (needed for report)</span></label>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="exam_det_result_interpretation">
                  </el-input>
                </el-col>
              </el-row>

              <el-row style="margin: 0px">
                <el-col :span="12" style="padding: 3px;">
                  <label>EXAMINATION NOTE</label>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="exam_det_exam_note">
                  </el-input>
                </el-col>
                <el-col :span="12" style="padding: 3px;">
                  <label>DESCRIPTION</label>
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="exam_det_description">
                  </el-input>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="ml-auto mr-auto">
          <el-row style="margin: 0px">
            <el-col :span="24" style="padding: 20px; text-align: center;">
              <el-button @click="modal_exam_click" type="info">Save</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="modal_exam_qc" width="70%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
      <h3 slot="header" class="title title-up">  </h3>
      <exam_qc exam_id = "" :app = "app"></exam_qc>
    </el-dialog>


    <el-dialog :visible.sync="modal_exam_msi_tmb" width="50%"  headerClasses="justify-content-center" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
      <h3 class="text-center" style="margin-bottom:50px"> Microsatellite instability (MSI) and Tumor mutational burden (TMB) </h3>

      <el-row style="margin: 0px; margin-bottom:50px">
        <el-col :span="12" :offset="3" style="padding: 3px">
          <span class="my_label">MSI Total number of variants: {{ this.msi_total_number_of_sites }}</span>
        </el-col>
        <el-col :span="9" style="padding: 3px">
          <span class="my_label">TMB Total variants: {{ this.tmb_total_variants }}</span>
        </el-col>

        <el-col :span="12" :offset="3" style="padding: 3px">
          <span class="my_label">MSI Number of somatic variants: {{ this.msi_number_of_somatic_sites }}</span>
        </el-col>
        <el-col :span="9" style="padding: 3px">
          <span class="my_label">TMB Variants after filter: {{ this.tmb_variants_after_filter }}</span>
        </el-col>

        <el-col :span="12" :offset="3" style="padding: 3px">
          <span class="my_label_blue">MSI %:  {{ this.msi_percent }}</span>
        </el-col>
        <el-col :span="9" style="padding: 3px">
          <span class="my_label_blue">TMB:  {{ this.tmb }}</span>
        </el-col>
      </el-row>
    </el-dialog>


    <el-dialog title="Edit Examination" :visible.sync="modal_exam_files" width="60%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
      <h5 v-if="g_examination" slot="header" class="title title-up">{{ this.g_examination.sample_id }}</h5>

      <div class="row">
        <el-tabs class="col-md-12" type="card" @tab-click="load_data_files" v-model="mytab">
          <el-tab-pane label="FASTQ Files" name="q">
            <mongo-files collection="q" :modify="true" :myfiles="u_f_q" :extensions="['gz', 'fastq', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="Result Files" name="v">
            <mongo-files collection="v" :modify="false" :myfiles="u_f_v" :extensions="['vcf', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="MultiQC & Metadata Files" name="m">
            <mongo-files collection="m" :modify="false" :myfiles="u_f_m" :extensions="['csv', 'html', 'xlsx', 'xls', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="Other Files" name="o">
            <mongo-files collection="o" :modify="true" :myfiles="u_f_o" :extensions="['pdf', 'csv', 'tsv', 'xlsx', 'docx', 'doc', 'html', 'htm', 'odt', 'ods', 'mkv', 'jpg', 'png', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="BAM/BAI Files" name="b">
            <mongo-files collection="b" :modify="false" :myfiles="u_f_b" :extensions="['bam', 'bai', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp === 'genetify'" label="Result Files" name="gf">
            <mongo-files collection="gf" :modify="true" :myfiles="u_f_gf" :extensions="['csv', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp === 'genetify'" label="Quality Control" name="gf_qc">
            <mongo-files collection="gf_qc" :modify="true" :myfiles="u_f_gf_qc" :extensions="['csv', 'pdf', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp === 'genetify'" label="Metadata" name="gf_r">
            <mongo-files collection="gf_r" :modify="true" :myfiles="u_f_gf_r" :extensions="['csv', ]"> </mongo-files>
          </el-tab-pane>


        </el-tabs>

      </div>
    </el-dialog>


  </div>
</template>
<script>
  import Vue from 'vue';
  import moment from 'moment';
  import {Table, TableColumn, Select, Option, Tabs, TabPane, Tag, Tooltip } from 'element-ui';
  import Button from 'src/components/UIComponents/Button';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import Loading from 'src/components/Layout/LoadingMainPanel.vue';
  import Vars from 'src/components/Views/Vars.vue';
  import PatientCard from 'src/components/Views/PatientCard.vue'
  import UserCard from 'src/components/Views/UserProfile/UserProfile/UserCard.vue'
  import MembersCard from 'src/components/Views/UserProfile/UserProfile/MembersCard.vue'
  import ExamQC from 'src/components/Views/ExamQC.vue'
  import MongoFiles from 'src/components/Views/MongoFiles.vue';

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  import FileSaver from 'file-saver';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Tabs);
  Vue.use(Select);
  Vue.use(Option);

  export default {
    name: 'exams',
    props: {
    },
    components: {
      PPagination,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Vars.name]: Vars,
      [Tag.name]: Tag,
      [Tooltip.name]: Tooltip,
      PatientCard,
      UserCard,
      MembersCard,
      [ExamQC.name]: ExamQC,
      [MongoFiles.name]: MongoFiles,


    },
    computed: {
      ...mapState({
        privat_be: state => state.privat_be,
        token: state => state.token,
        application: state => state.application,
        apps: state => state.apps,
        app: state => state.app,
        rapp: state => state.rapp,
        id_biorole: state => state.id_biorole,
        subject: state => state.subject,

        projects: state => state.projects,
        id_project: state => state.id_project,
        id_project_detail: state => state.id_project_detail,
        id_workflow: state => state.id_workflow,
        examinations: state => state.examinations,
        id_examination: state => state.id_examination,
        selected_exams: state => state.selected_exams,
        id_examination_detail: state => state.id_examination_detail,
        loading_e: state => state.loading_e,

        workflows: state => state.procts.workflows,
        user: state => state.user,
        cufi: state => state.cufi,
        pagination_v: state => state.pagination_v,
        sorting_v: state => state.sorting_v,
        patient: state => state.patient,
        id_patient: state => state.id_patient,
        qc_picard_hs: state => state.qc_picard_hs,
        qc_picard_amp: state => state.qc_picard_amp,
        qc_picard_amp_target: state => state.qc_picard_amp_target,
        chart_bar_a: state => state.chart_bar_a,
        vars_order: state => state.vars_order,
        vars_selected: state => state.vars_selected,
        exam_det: state => state.exam_det,
        l_hpo: state => state.l_hpo,
        l_icd11: state => state.l_icd11,

        f_q: state => state.f_q,
        f_m: state => state.f_m,
        f_o: state => state.f_o,
        f_v: state => state.f_v,
        f_b: state => state.f_b,
        f_c: state => state.f_c,
        f_gf: state => state.f_gf,
        f_gf_qc: state => state.f_gf_qc,
        f_gf_r: state => state.f_gf_r,

        u_f_q: state => state.u_f_q,
        u_f_m: state => state.u_f_m,
        u_f_o: state => state.u_f_o,
        u_f_v: state => state.u_f_v,
        u_f_b: state => state.u_f_b,
        u_f_c: state => state.u_f_c,
        u_f_gf: state => state.u_f_gf,
        u_f_gf_qc: state => state.u_f_gf_qc,
        u_f_gf_r: state => state.u_f_gf_r,

      }),

      ...mapGetters({
        g_project: 'g_project',
        g_examination: 'g_examination',
        g_examination_vars: 'g_examination_vars',
        g_examination_for_submit: 'g_examination_for_submit',
        g_exam_hpo: 'g_exam_hpo',
        g_exam_icd11: 'g_exam_icd11',

      }),

      pagedData_e () {
        return this.examinations.slice(this.from_e, this.to_e)
      },

      examinations_data () {
        if (!this.searchQuery_e) {
          this.pagination_e.total = this.examinations.length
          return this.pagedData_e
        }
        let result = this.examinations
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_e) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_e)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_e.total = result.length
        return result.slice(this.from_e, this.to_e)
      },

      to_e () {
        let highBound = this.from_e + this.pagination_e.perPage
        if (this.total_e < highBound) {
          highBound = this.total_e
        }
        return highBound
      },

      from_e () {
        return this.pagination_e.perPage * (this.pagination_e.currentPage - 1)
      },

      total_e () {
        this.pagination_e.total = this.examinations.length
        return this.examinations.length
      },

      modal_patient: {
        get () {
          return this.$store.state.modal_patient;
        },
        set (value) {
          this.$store.commit('m_modal_patient', value);
        }
      },

      modal_exam_detail: {
        get () {
          return this.$store.state.modal_exam_detail;
        },
        set (value) {
          this.$store.commit('m_modal_exam_detail', value);
        }
      },

      modal_exam_files: {
        get () {
          return this.$store.state.modal_exam_files;
        },
        set (value) {
          this.$store.commit('m_modal_exam_files', value);
        }
      },

      modal_exam_qc: {
        get () {
          return this.$store.state.modal_exam_qc;
        },
        set (value) {
          this.$store.commit('m_modal_exam_qc', value);
        }
      },

      modal_exam_msi_tmb: {
        get () {
          return this.$store.state.modal_exam_msi_tmb;
        },
        set (value) {
          this.$store.commit('m_modal_exam_msi_tmb', value);
        }
      },


      exam_det_sample_id: {
        get () { return this.$store.state.exam_det.sample_id; },
        set (value) { let mykey = "sample_id"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_int_sample_id: {
        get () { return this.$store.state.exam_det.int_sample_id; },
        set (value) { let mykey = "int_sample_id"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_personal_id: {
        get () { return this.$store.state.exam_det.personal_id; },
        set (value) { let mykey = "personal_id"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_sample_type: {
        get () { return this.$store.state.exam_det.sample_type; },
        set (value) { let mykey = "sample_type"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_sample_taken: {
        get () { return this.$store.state.exam_det.sample_taken; },
        set (value) { let mykey = "sample_taken"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_sample_received: {
        get () { return this.$store.state.exam_det.sample_received; },
        set (value) { let mykey = "sample_received"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_indication: {
        get () { return this.$store.state.exam_det.indication; },
        set (value) { let mykey = "indication"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_description: {
        get () { return this.$store.state.exam_det.description; },
        set (value) { let mykey = "description"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_indication_for_testing: {
        get () { return this.$store.state.exam_det.indication_for_testing; },
        set (value) { let mykey = "indication_for_testing"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_result_interpretation: {
        get () { return this.$store.state.exam_det.result_interpretation; },
        set (value) { let mykey = "result_interpretation"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_exam_note: {
        get () { return this.$store.state.exam_det.exam_note; },
        set (value) { let mykey = "exam_note"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_icd_note: {
        get () { return this.$store.state.exam_det.icd_note; },
        set (value) { let mykey = "icd_note"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_hpo_note: {
        get () { return this.$store.state.exam_det.hpo_note; },
        set (value) { let mykey = "hpo_note"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_sample_name_and_last_name: {
        get () { return this.$store.state.exam_det.sample_name_and_last_name; },
        set (value) { let mykey = "sample_name_and_last_name"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_birth_date: {
        get () { return this.$store.state.exam_det.birth_date; },
        set (value) { let mykey = "birth_date"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_gender: {
        get () { return this.$store.state.exam_det.gender; },
        set (value) { let mykey = "gender"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_referring_hospital: {
        get () { return this.$store.state.exam_det.referring_hospital; },
        set (value) { let mykey = "referring_hospital"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_referred_by: {
        get () { return this.$store.state.exam_det.referred_by; },
        set (value) { let mykey = "referred_by"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_hpo: {
        get () { return this.$store.state.exam_det.hpo; },
        set (value) { let mykey = "hpo"; this.$store.commit('m_exam_det', {mykey, value}); }
      },

      exam_det_icd11: {
        get () { return this.$store.state.exam_det.icd11; },
        set (value) { let mykey = "icd11"; this.$store.commit('m_exam_det', {mykey, value}); }
      },


      exam_visualisation: {
        get () { return this.$store.state.exam_visualisation; },
        set (value) { this.$store.commit('m_exam_visualisation', value); }
      },



    },


    data () {
      return {
        hpo_query: "",
        icd11_query: "",

        xxxxxxxxx: [],

        progress_bar_colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 80},
          {color: '#6f7ad3', percentage: 100}
        ],
        mytab: "q",

        files: [],
        exam_selections: [],


        formdata_patient: {
          sample_id: 0,
          description: "",
        },


        pagination_e: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50, 100, 250, 500],
          total: 0
        },
        searchQuery_e: '',
        propsToSearch_e: ['sample_id', ],
        tableColumns_e1: [
          {
            prop: 'id_examination',
            label: 'ID',
            minWidth: 60,
            align: "right"
          },
          {
            prop: 'sample_id',
            label: 'SampleID',
            minWidth: 100,
            align: "right"
          },

        ],

        tableColumns_e2: [
          {
            prop: 'description',
            label: 'Description',
            minWidth: 250
          },

        ],

        project_name: "",
        show_run_analyse_button: true,

        msi_total_number_of_sites: null,
        msi_number_of_somatic_sites: null,
        msi_percent: null,
        tmb_total_variants: null,
        tmb_variants_after_filter: null,
        tmb: null,
      }
    },

    methods: {
      ...mapMutations({
        m_id_examination: 'm_id_examination',
        m_selected_exams: 'm_selected_exams',
        m_modal_transcripts: 'm_modal_transcripts',
        m_modal_patient: 'm_modal_patient',
        m_modal_exam_detail: 'm_modal_exam_detail',
        m_modal_exam_files: 'm_modal_exam_files',
        m_id_examination_detail: 'm_id_examination_detail',
        m_id_patient: 'm_id_patient',
        m_modal_exam_qc: 'm_modal_exam_qc',
        m_modal_exam_msi_tmb: 'm_modal_exam_msi_tmb',
        m_qc_picard_hs: 'm_qc_picard_hs',
        m_qc_picard_amp: 'm_qc_picard_amp',
        m_qc_picard_amp_target: 'm_qc_picard_amp_target',
        m_chart_bar_a: 'm_chart_bar_a',
        m_vars_selected_tab: 'm_vars_selected_tab',
        m_vars_tab_name: 'm_vars_tab_name',
        m_exam_det: 'm_exam_det',
        m_cufi_yes: 'm_cufi_yes',
        m_cufi_load: 'm_cufi_load',
        m_cufi_selected: 'm_cufi_selected',

        m_f_q: 'm_f_q',
        m_f_m: 'm_f_m',
        m_f_o: 'm_f_o',
        m_f_v: 'm_f_v',
        m_f_b: 'm_f_b',
        m_f_c: 'm_f_c',
        m_f_gf: 'm_f_gf',
        m_f_gf_qc: 'm_f_gf_qc',
        m_f_gf_r: 'm_f_gf_r',

      }),


      ...mapActions({
        a_examinations: 'a_examinations',
        a_vars: 'a_vars',
        a_show_qc: 'a_show_qc',
        a_examination: 'a_examination',
        a_examination_validate: 'a_examination_validate',
        a_examination_delete: 'a_examination_delete',
        a_examinations_refresh: 'a_examinations_refresh',
        a_patient: 'a_patient',
        a_projects_refresh: 'a_projects_refresh',
        a_notify: 'a_notify',
        a_examination_update: 'a_examination_update',
        a_examination_gf_report: 'a_examination_gf_report',

        }),


      tableRowClassName({row, rowIndex}) {
        if (row.status > 18) {
          return 'row_0';
        } else if (row.status > 11) {
          return 'row_0';
        } else if (row.status > 9) {
          return 'row_0';
        } else if (row.status > 8) {
          return 'row_0';
        } else if (row.status > 1) {
          return 'row_0';
        } else if (row.status > 0) {
          return 'row_0';
        } else {
          return 'row_0';
        }
      },


      handle_exam_detail (index, row, event) {
        event.stopImmediatePropagation();
        this.m_id_examination_detail(row.id_examination);
        console.log(this.id_examination_detail)
        this.a_examination(row.id_examination);
        this.m_modal_exam_detail(true);
      },


      handle_exam_files (index, row, event) {
        event.stopImmediatePropagation();
        this.m_id_examination_detail(row.id_examination);
        this.a_examination(row.id_examination);
        this.mytab = "q";

        axios.get(process.env.VUE_APP_BE + '/get_files', { withCredentials: true, params: { "id_examination": this.id_examination_detail, "collection": "q" }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }})
          .then(response => {
            this.m_f_q(response.data["f_list"]);
          }, error => {
              console.log(error);
          });

        this.m_modal_exam_files(true);
      },


      handle_exam_validate (index, row, event, type) {
        event.stopImmediatePropagation();
        this.a_examination_validate( {"id_examination": row.id_examination, "act": type} );

        // if (row.status === 10) {
        //   row.status = 11;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else if (row.status > 10 && row.status < 20) {
        //   row.status = 21;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else if (row.status > 10 && row.status < 20) {
        //   row.status = 21;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else {
        //   row.status = 9;
        // }
        // this.m_id_examination_lock(row.id_examination);
        // this.m_modal_project_detail(true);
      },


      handle_run_analyze (event) {
        event.stopImmediatePropagation();
        for (let one of this.g_examination_for_submit) {
          this.a_examination_validate( {"id_examination": one.id_examination, "act": "BP"} );
        }
        this.show_run_analyse_button = false;

        setTimeout(function () { }, 5000);
        this.a_projects_refresh();
      },


      handle_exam_delete (index, row, event) {
        event.stopImmediatePropagation();
        this.a_examination_delete( {'id_examination': row.id_examination} );
      },


      handleShowQC_o (index, row, event, qc_type) {
        event.stopImmediatePropagation();
        this.a_show_qc( { "id_examination": row.id_examination, "type": qc_type } );
      },


      handleModalQC (index, row, event) {
        event.stopImmediatePropagation();
        axios.get(process.env.VUE_APP_BE + '/examination_qc', { withCredentials: true, params: { "id_examination": row.id_examination  }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, })
            .then(response => {
                this.m_qc_picard_hs(response.data["qc_picard_hs"]);
                this.m_qc_picard_amp(response.data["qc_picard_amp"]);
                this.m_qc_picard_amp_target(response.data["qc_picard_amp_target"]);
                this.m_chart_bar_a(response.data["chart_bar_a"]);
                this.m_modal_exam_qc(true);
            }, error => {
                console.log(error);
            });

      },


      handleModalMSI_TMB (index, row, event) {
        event.stopImmediatePropagation();
        this.msi_total_number_of_sites = row.msi_total_number_of_sites;
        this.msi_number_of_somatic_sites = row.msi_number_of_somatic_sites;
        this.msi_percent = row.msi_percent;
        this.tmb_total_variants = row.tmb_total_variants;
        this.tmb_variants_after_filter = row.tmb_variants_after_filter;
        this.tmb = row.tmb;
        this.m_modal_exam_msi_tmb(true);
      },


      handleShowQC (index, row, event) {
        event.stopImmediatePropagation();
        // console.log(event);
        // this.m_id_project_detail(row.id_project);
        let qc_filename = row.sample_id + '_multiqc_report.html';
        console.log(qc_filename);
        axios.get(process.env.VUE_APP_BE + '/showqc', { withCredentials: true, params: { id_project: row.id_project, id_examination: row.id_examination, filename: qc_filename, col: "m" }, headers: { 'Authorization': this.token, 'Content-Type': 'text/html', }, responseType: 'text', })
            .then(response => {
              var myWindow = window.open("", "_blank");
              myWindow.document.write(response.data);
              myWindow.document.close();
            }, error => {
                console.log(error);
            });
      },


      handleShowUCR (index, row, event) {
        event.stopImmediatePropagation();
        let qc_filename = row.sample_id + '_undercovered.html';
        axios.get(process.env.VUE_APP_BE + '/showqc', { withCredentials: true, params: { id_project: row.id_project, id_examination: row.id_examination, filename: qc_filename, col: "o" }, headers: { 'Authorization': this.token, 'Content-Type': 'text/html', }, responseType: 'text', })
            .then(response => {
              var myWindow = window.open("", "_blank");
              myWindow.document.write(response.data);
              myWindow.document.close();
            }, error => {
                console.log(error);
            });
      },


      handle_exam_reload (event) {
        event.stopImmediatePropagation();
        this.a_examinations( {"id_project": this.id_project} );
      },


      handle_unavailable (index, row, event) {
        event.stopImmediatePropagation();
        alert(`Not available in trial version.`);
      },


      handle_close_modal () {
        this.m_id_patient ( 0 );
      },


      handle_make_report (index, row, event) {
        event.stopImmediatePropagation();
        // axios.get(this.privat_be + '/test', { withCredentials: true, params: { "xxx": 123 }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        // axios.get(this.privat_be + '/test', { params: { "mycol": "examinations_ext", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
        axios.post(process.env.VUE_APP_BE + '/make_report', {}, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
                     var blob = new Blob([response.data]);
                     FileSaver.saveAs(blob, "report.pdf");
            }, error => {
                console.log(error);
            });
      },



      load_data_files(tab, event){
        let colls = ["q", "v", "m", "o", "b", "c", "gf", "gf_qc", "gf_r",];
        if (colls.includes(tab.name)) {
          // this.m_collection(tab.name);
          if (tab.name === "c") {
            var _col = "cnv";
          } else {
            var _col = tab.name;
          }

          axios.get(process.env.VUE_APP_BE + '/get_files', { withCredentials: true, params: { "id_examination": this.id_examination_detail, "collection": _col }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }})
              .then(response => {
                if (tab.name === "q") {
                  this.m_f_q(response.data["f_list"]);
                } else if (tab.name === "v") {
                  this.m_f_v(response.data["f_list"]);
                } else if (tab.name === "b") {
                  this.m_f_b(response.data["f_list"]);
                } else if (tab.name === "m") {
                  this.m_f_m(response.data["f_list"]);
                } else if (tab.name === "c") {
                  this.m_f_c(response.data["f_list"]);
                } else if (tab.name === "o") {
                  this.m_f_o(response.data["f_list"]);
                } else if (tab.name === "gf") {
                  this.m_f_gf(response.data["f_list"]);
                } else if (tab.name === "gf_qc") {
                  this.m_f_gf_qc(response.data["f_list"]);
                } else if (tab.name === "gf_r") {
                  this.m_f_gf_r(response.data["f_list"]);
                } else {
                }

              }, error => {
                  console.log(error);
              });
        } else {

        }


      },


      handleCurrentChange(val) {
        this.pagination_v.currentPage = 1;
        this.m_id_examination(val.id_examination);
        this.m_id_examination_detail(val.id_examination);
        this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": val.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );

        // this.m_vars_tab_name("b");

        // axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: this.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
        //   .then(response => {
        //     this.m_cufi_selected( response.data["id_cufi_default"] );
        //     this.m_cufi_load();

        //   }, error => {
        //     console.log(error);
        //   });


        // this.m_selected_exams([]);
        // this.$refs.examinations.clearSelection();
        // if (val && val.id_examination > 0 && val.status > 8) {
        //   this.pagination_v.currentPage = 1;
        //   this.m_id_examination(val.id_examination);
        //   this.m_id_examination_detail(val.id_examination);
        //   // this.m_vars_selected_tab("b");
        //   this.m_cufi_yes(true);
        //   this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "cufi": this.cufi, "selected": this.vars_selected } );
        // } else if (val && val.id_examination > 0) {
        //   this.m_id_examination(0);
        //   this.m_id_examination_detail(0);
        // } else {
        //   this.m_id_examination(0);
        //   this.m_id_examination_detail(0);
        // }
        // this.show_run_analyse_button = true;

      },


      handleSelectionChange(val) {
        // event.stopImmediatePropagation();
        this.m_vars_selected_tab("b");

        axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: this.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => {
            this.m_cufi_selected( response.data["id_cufi_default"] );
            this.m_cufi_load();
          }, error => {
            console.log(error);
          });

        this.m_cufi_yes(true);
        this.m_selected_exams(val);
        if (val.length > 0) {
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": 0, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        } else {
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected": this.vars_selected } );
        }
      },


      handlePatient(row, event) {
        // event.stopImmediatePropagation();
        this.m_modal_patient(true);
        this.m_id_patient ( row.id_patient );
        this.a_patient( row.hash );
        console.log(row.hash);

      },


      exam_visualisation_change(val) {
        this.m_id_examination(0);
        this.m_selected_exams([]);
        this.$refs.examinations.setCurrentRow();
        this.$refs.examinations.clearSelection();
        // this.m_exam_visualisation(val);
      },


      submit_exam() {
        // this.a_change_interpretation( this.formdata_change_class );
        // this.a_vars( {"order": this.vars_order, "cufi": this.cufi, "id_examination": this.id_examination} );
        this.a_notify({"message": "UPDATE."});
      },


      modal_exam_click() {
        this.a_examination_update();
        this.m_id_examination(0);
        this.$refs.examinations.setCurrentRow();
      },


      fetchEventsList () {
        if (this.id_project > 0) {
          this.a_examinations_refresh( {"id_project": this.id_project} );
        }
      },


      cancelAutoUpdate () {
        clearInterval(this.timer)
      },


      hpo_filter (query) {
        this.hpo_query = query.toLowerCase();
      },


      icd11_filter (query) {
        this.icd11_query = query.toLowerCase();
      },


      handleCurrentChangePage_e(val) {
        this.pagination_e.currentPage = val;
      },


      handleSizeChangePage_e(val) {
        this.pagination_e.perPage = val;
      },


      print_gf_report (index, row, event) {
        event.stopImmediatePropagation();

        this.$confirm('Are you sure?', 'Warning!', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          center: true
        }).then(() => {
          this.a_examination_gf_report( {'id_examination': row.id_examination} );
        }).catch(() => {

        });

      },

    },


    created () {
        this.timer = setInterval(this.fetchEventsList, 60000)
    },


    beforeMount () {
      // this.m_id_project(0);
    },

    mounted () {

    },


    afterDestroy () {
      this.m_id_examination(0);
    },


    beforeDestroy () {
      clearInterval(this.timer)
    },

  }
</script>

<style scoped lang="scss">
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}

.el-table .cell{
  line-height: 20px !important;
}

.el-table td, .el-table th {
  padding: 3px 0 !important;
}

.el-table .row_0 {
  background: rgb(253, 253, 253) !important;
}

.el-table .row_1 {
  background: rgb(243, 245, 152) !important;
}

.el-table .row_2 {
  background: rgb(248, 221, 171) !important;
}

.el-table .row_9 {
  background: rgb(252, 192, 82) !important;
}

.el-table .row_10 {
  background: rgb(120, 244, 248) !important;
}

.el-table .row_12 {
  background: rgb(154, 240, 168) !important;
}

.el-table .row_19 {
  background: rgb(5, 153, 29) !important;
}

.el-table .row_light_gray {
  background: rgb(243, 243, 243) !important;
}


.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #06d410 !important;
}

.el-table .info-row {
  background: #8fbdf1 !important;
}

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}



.mezera {
  margin-right: 10px;
}

label {
  display: inline-block;
  margin-bottom: 5px !important;
  margin-top: 15px !important;
  text-align: left !important;
  color: #005D72 !important;
  font-size: 13px !important;
  font-weight: 800 !important;
}

.my_label {
  text-align: right !important;
  font-size: 13px !important;
  font-weight: 800 !important;
}

.my_label_blue {
  color: #004080 !important;
  text-align: right !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}


.box-card-modal-01 {
  background: linear-gradient(0deg, #85ABB3, #E1E5E6) !important;
  padding: 20px 20px 50px 20px !important;
  margin-top: 0px !important;
  margin-bottom: 30px !important;
  border-radius: 7px !important;
}

.red_label {
  color: red !important;
}

.pagination_mezera {
  margin-top: 25px !important;
}
</style>
