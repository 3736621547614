<template>
  <el-row class="box-card-modal-01" style="padding-top: 110px">
    <el-col>
      <el-row :gutter="20" class="center_me">
        <el-col :span="24" class="my_trans_h3">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <h3>{{ public_name }}</h3>
                </div>
                <div class="text item">
                    <h5> Workflow Genes: </h5>
                </div>

                <div class="text item">
                    <el-tag
                        v-for="tag in my_genes"
                        :key="tag"
                        :type="tag"
                        effect="dark"
                        >
                        {{tag}}
                    </el-tag>
                </div>
            </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="2" style="margin: 0px">
        <el-col style="margin-top: 10px;">
          <p class="text-modal-02"></p>
        </el-col>

      </el-row>
    </el-col>
  </el-row>
</template>

<script>
  import axios from 'axios';

  export default {
    components: {

    },


    data() {
      return {
        id_workflow: 0,
        public_name: "",
        my_genes: [],
      }
    },


    beforeRouteUpdate(to, from, next) {
        // this.id_workflow = parseInt(to.params.id_workflow);
        next();
    },


    beforeRouteEnter(to, from, next) {
        next((vm) => {
        if (
            vm._data.hasOwnProperty("id_workflow") &&
            to.params.hasOwnProperty("id_workflow")
        ) {
            vm._data.id_workflow = parseInt(to.params.id_workflow);
            console.log(vm._data.id_workflow);

            axios.get(process.env.VUE_APP_BE + '/workflow_data', { withCredentials: false, params: { "id_workflow": vm._data.id_workflow }, headers: { 'Content-Type': 'application/json;charset=UTF-8', }})
                .then(response => {
                    vm._data.my_genes = response.data["my_genes"]
                    vm._data.public_name = response.data["public_name"]
                }, error => {
                    console.log(error);
                });

        console.log(vm._data.my_genes)


        }
        });

    },

  }
</script>

<style>
</style>
