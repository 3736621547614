<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <el-table class=""
                    ref="relations"
                    :data="relations_data"
                    border
                    highlight-current-row
                    style="width: 100%"
                    :row-class-name="tableRowClassName"
                    >
            <el-table-column v-for="column in tableColumns_e1"
                              :key="column.label"
                              :min-width="column.minWidth"
                              :prop="column.prop"
                              :label="column.label"
                              sortable
                              >
            </el-table-column>

            <el-table-column
              label="Status"
              min-width="80"
              align="center"
              >
              <template v-if="scope.row.status > ''" slot-scope="scope">
                <el-progress type="circle" :width="40" :percentage="scope.row.status * 5" :color="progress_bar_colors" :stroke-width=10></el-progress>
              </template>
            </el-table-column>

            <el-table-column v-for="column in tableColumns_e2"
                              :key="column.label"
                              :min-width="column.minWidth"
                              :prop="column.prop"
                              :label="column.label"
                              sortable
                              >
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">

              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from_e + 1}} to {{to_e}} of {{total_e}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination_e.currentPage"
                        :per-page="pagination_e.perPage"
                        :total="pagination_e.total">
          </p-pagination>
        </div>
      </div>
    </div>


    <modal :show.sync="modal_exam_detail" headerClasses="justify-content-center" :show-close="true">
      <h5 v-if="true" slot="header" class="title title-up">{{  }}</h5>
      <div class="row">
        <el-tabs class="col-md-12" type="border-card" @tab-click="">
          <el-tab-pane label="Examination">
            <div class="col-md-12">
              <form @submit.prevent="submit_exam">
                <div class="row">
                  <div class="col-md-12">
                      <label>SampleID</label>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="" v-model="formdata_exam.sample_id">
                      </div>

                      <label>Description</label>
                      <div class="form-group">
                        <textarea class="form-control" rows="8" placeholder="" v-model="formdata_exam.description"> </textarea>
                      </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mr-auto ml-auto">
                    <button type="submit" class="btn btn-info">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>
    </modal>

  </div>
</template>
<script>
  import Vue from 'vue';
  import moment from 'moment';
  import {Table, TableColumn, Select, Option, Tabs, TabPane, Tag, } from 'element-ui';
  import Button from 'src/components/UIComponents/Button';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import Loading from 'src/components/Layout/LoadingMainPanel.vue';
  import Modal from 'src/components/UIComponents/Modal'

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Tabs);
  Vue.use(Select);
  Vue.use(Option);


  export default {
    name: 'relations',
    props: {
      got_id_patient: {
        type: String,
        default: '0',
        description: ''
      },
    },
    components: {
      PPagination,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Tag.name]: Tag,
      Modal,
    },
    computed: {
      ...mapState({
        token: state => state.token,
        user: state => state.user,
        patient: state => state.patient,
        id_patient: state => state.id_patient,

      }),

      ...mapGetters({

      }),

      pagedData_e () {
        return this.examinations_patient.slice(this.from_e, this.to_e)
      },

      relations_data () {
        if (!this.searchQuery_e) {
          this.pagination_e.total = this.examinations_patient.length
          return this.pagedData_e
        }
        let result = this.examinations_patient
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_e) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_e)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_e.total = result.length
        return result.slice(this.from_e, this.to_e)
      },

      to_e () {
        let highBound = this.from_e + this.pagination_e.perPage
        if (this.total_e < highBound) {
          highBound = this.total_e
        }
        return highBound
      },

      from_e () {
        return this.pagination_e.perPage * (this.pagination_e.currentPage - 1)
      },

      total_e () {
        this.pagination_e.total = this.examinations_patient.length
        return this.examinations_patient.length
      },

      modal_patient: {
        get () {
          return this.$store.state.modal_patient;
        },
        set (value) {
          this.$store.commit('m_modal_patient', value);
        }
      },

      modal_exam_detail: {
        get () {
          return this.$store.state.modal_exam_detail;
        },
        set (value) {
          this.$store.commit('m_modal_exam_detail', value);
        }
      },

    },


    data () {
      return {
        progress_bar_colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 80},
          {color: '#6f7ad3', percentage: 100}
        ],

        files: [],
        formdata_exam: {
          sample_id: "",
          description: "",
        },

        formdata_patient: {
          sample_id: 0,
          description: "",
        },

        formdata_report: {
          description: "",
        },

        pagination_e: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5,],
          total: 0
        },

        tableColumns_e1: [
          {
            prop: 'id_examination',
            label: 'ID',
            minWidth: 60,
            align: "right"
          },
          {
            prop: 'sample_id',
            label: 'SampleID',
            minWidth: 100,
            align: "right"
          },

        ],

        tableColumns_e2: [
          {
            prop: 'description',
            label: 'Description',
            minWidth: 300
          },

        ],

        project_name: "",

      }
    },

    methods: {
      tableRowClassName({row, rowIndex}) {
        if (row.status > 18) {
          return 'row_light_gray';
        } else if (row.status > 11) {
          return 'row_light_gray';
        } else if (row.status > 9) {
          return 'row_light_gray';
        } else if (row.status > 8) {
          return 'row_light_gray';
        } else if (row.status > 1) {
          return 'row_light_gray';
        } else if (row.status > 0) {
          return 'row_light_gray';
        } else {
          return 'row_light_gray';
        }
      },


      handle_exam_detail (index, row, event) {
        event.stopImmediatePropagation();

      },


      handle_exam_detail (index, row, event) {
        event.stopImmediatePropagation();
        // this.formdata_ep.id_project = row.id_project;
        // this.formdata_ep.name = row.name;
        // this.formdata_ep.description = row.description;
        this.m_id_examination_detail(row.id_examination);
        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        // this.formdata_exam.sample_id = this.g_examination.sample_id;
        // this.formdata_exam.description = this.g_examination.description;
        this.m_modal_exam_detail(true);
      },

      handle_exam_validate (index, row, event, type) {
        event.stopImmediatePropagation();
        this.a_examination_validate( {"id_examination": row.id_examination, "act": type} );

        // if (row.status === 10) {
        //   row.status = 11;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else if (row.status > 10 && row.status < 20) {
        //   row.status = 21;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else if (row.status > 10 && row.status < 20) {
        //   row.status = 21;
        //   this.a_examination( {"cufi": this.cufi, "id_examination": this.id_examination} );
        // } else {
        //   row.status = 9;
        // }
        // this.m_id_examination_lock(row.id_examination);
        // this.m_modal_project_detail(true);
      },

      handle_exam_delete (index, row, event) {
        event.stopImmediatePropagation();
        this.a_examination_delete( {'id_examination': row.id_examination} );
      },

      handleShowQC (index, row, event, qc_type) {
        event.stopImmediatePropagation();
        this.a_show_qc( { "id_examination": row.id_examination, "type": qc_type } );
      },

      handle_exam_reload (event) {
        event.stopImmediatePropagation();
        this.a_examinations( {"id_project": this.id_project} );
      },

      handle_unavailable (index, row, event) {
        event.stopImmediatePropagation();
        alert(`Not available in trial version.`);
      },

      handle_close_modal () {
        console.log("Zaviram modal.");
      },



      ...mapMutations({
        m_id_examination: 'm_id_examination',
        m_modal_transcripts: 'm_modal_transcripts',
        m_modal_patient: 'm_modal_patient',
        m_modal_exam_detail: 'm_modal_exam_detail',
        m_id_examination_detail: 'm_id_examination_detail',
      }),


      ...mapActions({
        }),


      handleCurrentChange_e(val) {
        if (val && val.id_examination > 0 && val.status > 8) {
          this.m_id_examination(val.id_examination);
          this.m_id_examination_detail(val.id_examination);
          this.a_vars( {"cufi": this.cufi, "id_examination": this.id_examination} );
        } else if (val && val.id_examination > 0) {
          this.m_id_examination(0);
          this.m_id_examination_detail(0);
        } else {
          this.m_id_examination(0);
          this.m_id_examination_detail(0);
        }
      },

      handlePatient(row, event) {
        event.stopImmediatePropagation();
        this.m_modal_patient(true);
        this.a_patient( row.id_patient );



      },

      submit_exam() {
        // this.a_change_interpretation( this.formdata_change_class );
        // this.a_vars( {"order": this.vars_order, "er_switch": this.er_switch, "cufi": this.cufi, "id_examination": this.id_examination} );
        // this.a_notify("Interpretation was changed.");
      },

      submit_patient() {

      },

      submit_report() {

      },

    },


  }
</script>

<style lang="scss" scoped>
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}

.el-table {
  font-size: 12px !important;
}

.el-table .cell{
  line-height: 20px !important;
}

.el-table td, .el-table th {
  padding: 3px 0 !important;
}

.el-table .row_0 {
  background: rgb(253, 253, 253) !important;
}

.el-table .row_1 {
  background: rgb(243, 245, 152) !important;
}

.el-table .row_2 {
  background: rgb(248, 221, 171) !important;
}

.el-table .row_9 {
  background: rgb(252, 192, 82) !important;
}

.el-table .row_10 {
  background: rgb(120, 244, 248) !important;
}

.el-table .row_12 {
  background: rgb(154, 240, 168) !important;
}

.el-table .row_19 {
  background: rgb(5, 153, 29) !important;
}

.el-table .row_light_gray {
  background: rgb(243, 243, 243) !important;
}


.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #06d410 !important;
}

.el-table .info-row {
  background: #8fbdf1 !important;
}

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}

.table > thead > tr > th, .el-table table > thead > tr > th {
  font-size: 12px !important;
}

.mezera {
  margin-right: 10px;
}
</style>
