<template>
  <tr>
    <td>
      <p-checkbox :checked="true"></p-checkbox>
    </td>
    <td class="img-row">
      <div class="img-wrapper">
        <i class="fas fa-user-circle fa-2x" v-bind:class="task.color"></i>
      </div>
    </td>
    <td class="text-left">{{task.title}}</td>
    <td class="td-actions text-right">

    </td>
  </tr>
</template>
<script>
  import { Tooltip } from 'element-ui'

  export default {
    components: {
      [Tooltip.name]: Tooltip
    },
    props: {
      task: {
        type: Object,
        default: () => {
          return {
            title: ''
          }
        }
      },
      index: Number
    },
    methods: {

    }
  }
</script>
<style>
.blue {
  color: rgb(56, 177, 247) !important;
}

.grau2 {
  color: rgb(68, 71, 78) !important;
}

.grau1 {
  color: rgb(172, 173, 180) !important;
}

.orange {
  color: rgb(173, 72, 24) !important;
}

</style>
