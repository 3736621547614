<template>
  <div class="row dolu">
    <div class="col-md-6">
      <h6 class="title">Accounts</h6>
    </div>
    <div class="col-md-6">
    </div>

  </div>
</template>
<script>
  import Vue from 'vue';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';

  export default {
    components: {

    },

    data () {
      return {

      }
    },

    computed: {
      ...mapState({
        user: state => state.user,
      }),

      ...mapGetters({

      }),

    },


    methods: {
      ...mapMutations({

      }),


      ...mapActions({

        }),

    },

    beforeMount () {

    },

  }
</script>
<style lang="scss">
.dolu {
  padding-top: 80px !important;
}


</style>
