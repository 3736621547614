<template>
  <form class="form-horizontal">
    <div class="col-md-12 bg_gray">
      <div class="row">
        <div class="col-md-2 top_margin_3">
          <div class="row">
            <label class="col-md-7 col-form-label top_margin_3 and_label">READ DEPTH min. &nbsp;&nbsp;&nbsp;<el-checkbox v-model="cufi_chb_read_depth"> </el-checkbox> </label>
            <div class="col-md-5 top_margin_3">
              <el-input-number :disabled="!cufi_chb_read_depth" placeholder="" controls-position="right" v-model="cufi_read_depth" size="small" :step="10" :min="0"></el-input-number>
            </div>

            <label class="col-md-7 col-form-label top_margin_3 and_label">FREQ VCF min. (%) &nbsp;&nbsp;&nbsp;<el-checkbox v-model="cufi_chb_freq_vcf"> </el-checkbox> </label>
            <div class="col-md-5 top_margin_3">
              <el-input-number v-if="rapp === 'fastras'" :disabled="!cufi_chb_freq_vcf" placeholder="" controls-position="right" v-model="cufi_freq_vcf" size="small" :precision="1" :step="0.1" :min="0.5" :max="100.0"></el-input-number>
              <el-input-number v-else placeholder="" :disabled="!cufi_chb_freq_vcf" controls-position="right" v-model="cufi_freq_vcf" size="small" :precision="1" :step="0.1" :min="1.0" :max="100.0"></el-input-number>
            </div>

            <label class="col-md-7 col-form-label top_margin_3 and_label">Freq project max. &nbsp;&nbsp;&nbsp;<el-checkbox v-model="cufi_chb_freq_project"> </el-checkbox> </label>
            <div class="col-md-5 top_margin_3">
              <el-input-number :disabled="!cufi_chb_freq_project" placeholder="" controls-position="right" v-model="cufi_freq_project" size="small"></el-input-number>
            </div>

            <label class="col-md-7 col-form-label top_margin_3 and_label">Freq subject max. &nbsp;&nbsp;&nbsp;<el-checkbox v-model="cufi_chb_freq_subject"> </el-checkbox> </label>
            <div class="col-md-5 top_margin_3">
              <el-input-number :disabled="!cufi_chb_freq_subject" placeholder="" controls-position="right" v-model="cufi_freq_subject" size="small"></el-input-number>
            </div>

            <label class="col-md-7 col-form-label top_margin_3 and_label">GNOMAD MAX AF &nbsp;&nbsp;&nbsp;<el-checkbox v-model="cufi_chb_gnomad_max_af"> </el-checkbox> </label>
            <div class="col-md-5 top_margin_3">
              <el-input-number :disabled="!cufi_chb_gnomad_max_af" placeholder="" controls-position="right" v-model="cufi_gnomad_max_af" size="small" :precision="4" :step="0.0001" :min="0.0001" :max="1.0000"></el-input-number>
            </div>

            <label class="col-md-7 col-form-label top_margin_3 and_label">ABQ MIN &nbsp;&nbsp;&nbsp;<el-checkbox v-model="cufi_chb_abq"> </el-checkbox> </label>
            <div class="col-md-5 top_margin_3">
              <el-input-number :disabled="!cufi_chb_abq" placeholder="" controls-position="right" v-model="cufi_abq" size="small" :precision="0" :step="1" :min="0" :max="100"></el-input-number>
            </div>

          </div>
        </div>

        <div class="col-md-4 top_margin_3">
          <div class="row">
            <label class="col-md-4 col-form-label top_margin_3 and_label">GENOTYPE</label>
            <div class="col-md-8 top_margin_3">
              <fg-input>
                <el-select class=""
                  v-model="cufi_genotype"
                  placeholder=""
                  size="small"
                  multiple
                  :disabled="false">
                  <el-option
                    v-for="item in l_gt"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>

            <label class="col-md-4 col-form-label top_margin_3 and_label">IMPACT</label>
            <div class="col-md-8 top_margin_3">
              <fg-input>
                <el-select class=""
                          size="small"
                          multiple
                          filterable
                          placeholder=""
                          v-model="cufi_impacts"
                          :disabled="false">
                  <el-option v-for="one in l_impacts"
                            :value="one"
                            :label="one"
                            :key="one">
                  </el-option>
                </el-select>
              </fg-input>
            </div>

            <label class="col-md-4 col-form-label top_margin_3 and_label">CONSEQUENCE</label>
            <div class="col-md-8 top_margin_3">
              <fg-input>
                <el-select class=""
                  size="small"
                  multiple
                  filterable
                  :filter-method="select_filter"
                  placeholder=""
                  v-model="cufi_consequences"
                  :disabled="false">
                  <el-option v-for="one in l_consequences"
                            :value="one"
                            :label="one"
                            :key="one">
                  </el-option>
                </el-select>
              </fg-input>
            </div>

            <label class="col-md-4 col-form-label top_margin_3 and_label">CLINVAR</label>
            <div class="col-md-8 top_margin_3">
              <fg-input>
                <el-select class=""
                  size="small"
                  multiple
                  filterable
                  :filter-method="select_filter"
                  placeholder=""
                  v-model="cufi_clinvar"
                  :disabled="false">
                  <el-option v-for="one in d_clinvars"
                            :value="one.clinvar_id"
                            :label="one.clinvar"
                            :key="one.clinvar_id">
                  </el-option>
                </el-select>
              </fg-input>
            </div>

          </div>
        </div>

        <div class="col-md-6 top_margin_3">
          <div class="row bottom_margin_3">
            <label class="col-md-3 col-form-label top_margin_3 and_label">Int. classes</label>
            <div class="col-md-9 top_margin_3">
              <el-checkbox-group v-model="cufi_my_iclasses" size="small" :disabled="rapp === 'fastras'">
                <el-checkbox-button v-for="one of l_iclasses" :label="one.id_iclass" :key="one.id_iclass" :value="one.id_iclass">{{one.iclass}}</el-checkbox-button>
              </el-checkbox-group>
            </div>

            <label class="col-md-3 col-form-label top_margin_3 and_label">PSEUDOPANELS</label>
            <div class="col-md-9 top_margin_3">
              <el-select class=""
                size="small"
                multiple
                @change="pseudopanels_change"
                placeholder=""
                v-model="cufi_my_pseudopanels"
                :disabled="rapp === 'fastras'">
                <el-option v-for="one in Object.keys(d_pseudopanels)"
                          :value="one"
                          :label="one"
                          :key="one">
                </el-option>
              </el-select>
            </div>

            <label class="col-md-3 col-form-label top_margin_3 and_label"> </label>
            <div class="col-md-9 top_margin_3">
              <el-button @click.native="click_hpo_terms"> HPO TERMS </el-button>
            </div>

          </div>
        </div>

        <div class="col-md-2 offset-md-1">
          <div class="row bottom_margin_3">
              <label class="col-md-6 col-form-label top_margin_3 and_label">Synonymous variants</label>
              <div class="col-md-6 ml-auto mr-auto top_margin_3">
                <div class="block top_margin_3">
                  <el-switch
                    v-model="cufi_syn_vars"
                    active-color="#ddd"
                    inactive-color="#777"
                    active-text="YES"
                    inactive-text="NO"
                    >
                  </el-switch>
                </div>
              </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="row bottom_margin_3">
              <label class="col-md-6 col-form-label top_margin_3 and_label">Split MNV</label>
              <div class="col-md-6 ml-auto mr-auto top_margin_3">
                <div class="block top_margin_3">
                  <el-switch
                    v-model="cufi_mnv_switch"
                    active-color="#ddd"
                    inactive-color="#777"
                    active-text="YES"
                    inactive-text="NO"
                    >
                  </el-switch>
                </div>
              </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="row bottom_margin_3">
              <label class="col-md-6 col-form-label top_margin_3 and_label">Show homopolymers</label>
              <div class="col-md-6 ml-auto mr-auto top_margin_3">
                <div class="block top_margin_3">
                  <el-switch
                    v-model="cufi_fil_hompol"
                    active-color="#ddd"
                    inactive-color="#777"
                    active-text="YES"
                    inactive-text="NO"
                    >
                  </el-switch>
                </div>
              </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="row bottom_margin_3">
              <label class="col-md-6 col-form-label top_margin_3 and_label">Show repeats</label>
              <div class="col-md-6 ml-auto mr-auto top_margin_3">
                <div class="block top_margin_3">
                  <el-switch
                    v-model="cufi_fil_repeats"
                    active-color="#ddd"
                    inactive-color="#777"
                    active-text="YES"
                    inactive-text="NO"
                    >
                  </el-switch>
                </div>
              </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="row bottom_margin_3">
              <label class="col-md-6 col-form-label top_margin_3 and_label">Show other</label>
              <div class="col-md-6 ml-auto mr-auto top_margin_3">
                <div class="block top_margin_3">
                  <el-switch
                    v-model="cufi_fil_other"
                    active-color="#ddd"
                    inactive-color="#777"
                    active-text="YES"
                    inactive-text="NO"
                    >
                  </el-switch>
                </div>
              </div>
          </div>
        </div>


        <div class="col-md-12">
          <div class="row bottom_margin_3">
            <label class="col-md-1 col-form-label top_margin_3 and_label">GENES</label>

            <div class="col-md-1 top_margin_3">
              <el-button v-if="show_genes" type="warning" size="mini" icon="icofont-eye-blocked" placeholder="" @click.native="genes_hide"></el-button>
              <el-button v-else type="show" size="mini" icon="icofont-eye" placeholder="" @click.native="genes_show"></el-button>

              <el-button type="danger" size="mini" icon="icofont-ui-delete" placeholder="Delete all genes from input ..." @click.native="cufi_genes_del"></el-button>
            </div>

            <div v-show="show_genes" class="col-md-9 top_margin_3">
              <fg-input>
                <el-select
                  size="medium"
                  multiple
                  filterable
                  remote
                  @remove-tag="genes_remove"
                  @clear="genes_clear"
                  placeholder="Type at least 3 characters..."
                  :remote-method="AC_genes"
                  :loading="AC_genes_loading"
                  no-data-text=" "
                  v-model="cufi_genes"
                  :disabled="false">
                  <el-option v-for="one in l_gene"
                            :value="one"
                            :label="one"
                            :key="one"
                            >
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div v-show="!show_genes" class="col-md-9 top_margin_3">
            </div>

          </div>
        </div>
      </div>

      <el-divider />

      <div class="row">

        <div class="col-md-3">
          <el-select
            class="select-info"
            v-model="cufi_selected"
            placeholder="Select filter"
            style="width: 90%"
            filterable
            @change="cufi_change"

            >
            <el-option
              class="select-info"
              v-for="item of g_cufis"
              :key="item.id_cufi"
              :label="item.cufi_name"
              :value="item.id_cufi"
              >
              <span v-if="item.cufi_lock" style="float: left"> <i class="el-icon-lock"></i> {{ item.cufi_name }} </span>
            </el-option>
          </el-select>
        </div>

        <div class="col-md-9 bottom_margin_3">
          <el-button-group>
            <el-button :disabled="!(cufi_selected > 0) || cufi.cufi_lock" type="success" style="width: 150px" @click.native="cufi_save"> SAVE </el-button>
            <el-button type="success" plain style="width: 150px" @click.native="cufi_save_as"> SAVE AS </el-button>
            <el-button type="info" disabled style="width: 10px"> &nbsp; </el-button>
            <el-button :disabled="!(cufi_selected > 0)" style="width: 150px" @click.native="cufi_set_default"> SET AS DEFAULT </el-button>
            <el-button type="primary" style="width: 150px" @click.native="cufi_load_default"> LOAD DEFAULT </el-button>
            <el-button type="info" disabled style="width: 10px"> &nbsp; </el-button>
            <el-button type="warning" style="width: 150px" @click.native="clean_filter"> CLEAN </el-button>
            <el-button :disabled="!(cufi_selected > 0) || cufi.cufi_lock" type="danger" style="width: 150px" @click.native="cufi_delete"> DELETE </el-button>
            <el-button type="info" disabled style="width: 30px"> &nbsp; </el-button>
            <el-button type="info" plain style="width: 150px" @click.native="show_vars(true)"> SHOW VARIANTS </el-button>
          </el-button-group>
        </div>

      </div>
    </div>
  </form>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';

  export default {
    name: 'vars_cufi',
    props: {
    },
    components: {
    },


    data () {
      return {
        show: true,
        show_genes: true,

        selects: {
          simple: '',
          multiple: 'ARS'
        },

        l_gt: ["0/1", "1/1"],
        l_impacts: ["LOW", "HIGH", "MODERATE", "MODIFIER"],

        l_search: [
          {
            key: "pos",
            value: "POSITION"
          },
          {
            key: "snp",
            value: "dbSNP"
          },

        ],

        s_search: '',
        searchQuery_v: '',
        propsToSearch_v: [],
        AC_genes_loading: false,

      }
    },

    computed: {
      ...mapState({
        vars: state => state.vars,
        l_gene: state => state.l_gene,
        l_consequences: state => state.l_consequences,
        l_features: state => state.l_features,
        l_clinvars: state => state.l_clinvars,
        d_clinvars: state => state.d_clinvars,
        l_clinvar_dns: state => state.l_clinvar_dns,
        l_iclasses: state => state.l_iclasses,
        d_a_cols: state => state.d_a_cols,
        d_pseudopanels: state => state.d_pseudopanels,
        pagination_v: state => state.pagination_v,
        sorting_v: state => state.sorting_v,
        fastras_freq: state => state.fastras_freq,
        search_vars_by_select: state => state.search_vars_by_select,
        search_vars_by_value: state => state.search_vars_by_value,

        cufi: state => state.cufi,
        cufi_yes: state => state.cufi_yes,
        syn_vars: state => state.cufi.syn_vars,
        id_project: state => state.id_project,
        id_examination: state => state.id_examination,
        id_workflow: state => state.id_workflow,
        selected_exams: state => state.selected_exams,
        user_setup: state => state.user_setup,

        token: state => state.token,
        user: state => state.user,
        subject: state => state.subject,
        application: state => state.application,
        app: state => state.app,
        rapp: state => state.rapp,

      }),

      ...mapGetters({
        g_gene: 'g_gene',
        g_examination: 'g_examination',
        g_examination_export: 'g_examination_export',
        g_project: 'g_project',
        g_iclasses: 'g_iclasses',
        g_cufis: 'g_cufis',

        }),

      slider_value: {
        get () { return this.$store.state.slider_value; },
        set (value) { this.$store.commit('m_slider_value', value); }
      },

      cufi_read_depth: {
        get () { return this.$store.state.cufi.read_depth; },
        set (value) { let mykey = "read_depth"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_freq_vcf: {
        get () { return this.$store.state.cufi.freq_vcf; },
        set (value) { let mykey = "freq_vcf"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_genotype: {
        get () { return this.$store.state.cufi.genotype; },
        set (value) { let mykey = "genotype"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_freq_project: {
        get () { return this.$store.state.cufi.freq_project; },
        set (value) { let mykey = "freq_project"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_freq_subject: {
        get () { return this.$store.state.cufi.freq_subject; },
        set (value) { let mykey = "freq_subject"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_impacts: {
        get () { return this.$store.state.cufi.impacts; },
        set (value) { let mykey = "impacts"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_consequences: {
        get () { return this.$store.state.cufi.consequences; },
        set (value) { let mykey = "consequences"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_clinvar: {
        get () { return this.$store.state.cufi.clinvar; },
        set (value) { let mykey = "clinvar"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_gnomad_max_af: {
        get () { return this.$store.state.cufi.gnomad_max_af; },
        set (value) { let mykey = "gnomad_max_af"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_abq: {
        get () { return this.$store.state.cufi.abq; },
        set (value) { let mykey = "abq"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_genes: {
        get () { return this.$store.state.cufi.genes; },
        set (value) { let mykey = "genes"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_my_iclasses: {
        get () { return this.$store.state.cufi.my_iclasses; },
        set (value) { let mykey = "my_iclasses"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_syn_vars: {
        get () { return this.$store.state.cufi.syn_vars; },
        set (value) { let mykey = "syn_vars"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_mnv_switch: {
        get () { return this.$store.state.cufi.mnv_switch; },
        set (value) { let mykey = "mnv_switch"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_fil_hompol: {
        get () { return this.$store.state.cufi.fil_hompol; },
        set (value) { let mykey = "fil_hompol"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_fil_repeats: {
        get () { return this.$store.state.cufi.fil_repeats; },
        set (value) { let mykey = "fil_repeats"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_fil_other: {
        get () { return this.$store.state.cufi.fil_other; },
        set (value) { let mykey = "fil_other"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_chb_read_depth: {
        get () { return this.$store.state.cufi.chb_read_depth; },
        set (value) { let mykey = "chb_read_depth"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_chb_freq_vcf: {
        get () { return this.$store.state.cufi.chb_freq_vcf; },
        set (value) { let mykey = "chb_freq_vcf"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_chb_freq_project: {
        get () { return this.$store.state.cufi.chb_freq_project; },
        set (value) { let mykey = "chb_freq_project"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_chb_freq_subject: {
        get () { return this.$store.state.cufi.chb_freq_subject; },
        set (value) { let mykey = "chb_freq_subject"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_chb_gnomad_max_af: {
        get () { return this.$store.state.cufi.chb_gnomad_max_af; },
        set (value) { let mykey = "chb_gnomad_max_af"; this.$store.commit('m_cufi', {mykey, value}); }
      },

      cufi_chb_abq: {
        get () { return this.$store.state.cufi.chb_abq; },
        set (value) { let mykey = "chb_abq"; this.$store.commit('m_cufi', {mykey, value}); }
      },


      cufi_my_pseudopanels: {
        get () { return this.$store.state.cufi.my_pseudopanels; },
        set (value) { let mykey = "my_pseudopanels"; this.$store.commit('m_cufi', {mykey, value}); }
      },


      cufi_selected: {
        get () { return this.$store.state.cufi_selected; },
        set (value) { this.$store.commit('m_cufi_selected', value); }
      },

      // my_pseudopanels: {
      //   get () { return this.$store.state.my_pseudopanels; },
      //   set (value) { this.$store.commit('m_my_pseudopanels', value); }
      // },


    },


    methods: {
      ...mapMutations({
        m_slider_value: 'm_slider_value',
        m_ss_gene: 'm_ss_gene',
        m_vars: 'm_vars',
        m_id_examination: 'm_id_examination',
        m_cufi_yes: 'm_cufi_yes',
        m_fastras_freq: 'm_fastras_freq',
        m_modal_pseudopanels: 'm_modal_pseudopanels',
        m_cufi_selected: 'm_cufi_selected',
        m_cufi_load: 'm_cufi_load',
        m_d_cufis: 'm_d_cufis',
        m_l_cufis: 'm_l_cufis',
        m_modal_hpo_terms: 'm_modal_hpo_terms',

      }),


      ...mapActions({
        a_varsall: 'a_varsall',
        a_vars: 'a_vars',
        a_notify: 'a_notify',
        a_hpo_get: 'a_hpo_get',

      }),


      show_vars(cufi_yes){
        this.cufi.cufi_yes = cufi_yes;
        this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project } );
      },


      select_filter_gene(value){
        this.m_ss_gene(value);
      },


      select_filter(value){
        console.log(value);
      },

      // select_impact(value){
      //   console.log(value);
      //   this.m_cufi_impacts(value);
      // },


      clean_filter() {
        this.show_genes = false;
        this.m_cufi_selected( 1 );
        this.m_cufi_load();

        this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );

        this.$notify({
          title: 'INFO',
          message: 'The filter has been cleared, and now it displays all variants.',
          type: 'primary',
          position: 'top-right',
        });
      },


      cufi_genes_del() {
          // this.show_genes = false;
          // let mykey = "genes";
          // let myval = [];
          // this.$store.commit('m_cufi', {mykey, myval});
          this.cufi.genes = [];
          this.cufi.my_pseudopanels = [];
      },


      genes_show() {
        this.show_genes = true;
      },


      genes_hide() {
        this.show_genes = false;
      },


      pseudopanels_change (val) {
        let newarray = [];
        for (let one of val) {
          newarray.push(...this.d_pseudopanels[one]);
        }
        newarray.push(...this.cufi_genes);
        let sorted_genes = [...new Set(newarray)].sort()
        this.cufi.genes = sorted_genes;
        this.show_genes = true;
      },


      genes_remove (val) {
        this.cufi.my_pseudopanels = [];
      },


      genes_clear () {
        let mykey = "genes";
        let value = [];
        this.$store.commit('m_cufi', {mykey, value});

        this.$store.commit('m_cufi_my_pseudopanels', value);

      },


      // cufi_add_pseudopanel() {
      //   this.m_modal_pseudopanels(true);

      // },


      cufi_change(val) {
        this.m_cufi_load();
        this.show_vars(true);
      },


      cufi_save() {
        axios.post(process.env.VUE_APP_BE + '/cufi', {cufi: this.cufi}, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => {
            this.m_d_cufis( response.data["d_cufis"] );
            this.m_l_cufis( response.data["l_cufis"] );

            this.$notify({
              title: 'INFO',
              message: 'Filter was saved',
              type: 'success',
              position: 'top-right',
            });
          }, error => {
            console.log(error);
          });
      },


      cufi_save_as() {
        this.$prompt('Please input name', 'NEW FILTER', {
          confirmButtonText: 'CREATE NEW FILTER',
          cancelButtonText: 'Cancel',
        }).then(({ value }) => {
          this.cufi.id_cufi = null;
          this.cufi.cufi_name = value;
          this.cufi.id_workflow = this.id_workflow;
          this.cufi_selected = null;

          axios.post(process.env.VUE_APP_BE + '/cufi', {cufi: this.cufi}, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              this.m_d_cufis( response.data["d_cufis"] );
              this.m_l_cufis( response.data["l_cufis"] );

              this.$alert('New filter is ready.', 'NEW FILTER', {
                confirmButtonText: 'OK',
                callback: action => {
                  if (response.data["cufi_insert"] > 0) {
                    this.cufi_selected = response.data["cufi_insert"];
                    this.cufi.id_cufi = response.data["cufi_insert"];

                  }
                }
              });
            }, error => {
              console.log(error);
            });

        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Input canceled'
          });
        });

      },


      cufi_set_default() {
        axios.patch(process.env.VUE_APP_BE + '/cufi', {cufi_selected: this.cufi_selected, id_workflow: this.id_workflow}, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => {
            this.m_d_cufis( response.data["d_cufis"] );
            this.m_l_cufis( response.data["l_cufis"] );

            this.$notify({
              title: 'INFO',
              message: 'Current filter was set as default.',
              type: 'success',
              position: 'top-right',
            });
          }, error => {
            console.log(error);
          });
      },


      cufi_load_default() {
        axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: this.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => {
            this.m_cufi_selected( response.data["id_cufi_default"] );
            this.m_cufi_load();
            this.show_vars(true);

            this.$notify({
              title: 'INFO',
              message: 'Default filter was loaded.',
              type: 'primary',
              position: 'top-right',
            });
          }, error => {
            console.log(error);
          });
      },


      cufi_delete() {
          this.$confirm("Are you sure?", 'DELETE FILTER?', {
            confirmButtonText: "YES",
            cancelButtonText: "NO",
            type: 'danger',
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
          }).then(() => {
            axios.delete(process.env.VUE_APP_BE + '/cufi', { data: {id_cufi: this.cufi_selected}, withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
              .then(response => {
                this.cufi_selected = null;
                this.m_d_cufis( response.data["d_cufis"] );
                this.m_l_cufis( response.data["l_cufis"] );

                this.clean_filter()

                this.$notify({
                  title: 'INFO',
                  message: 'Selected filter was deleted.',
                  type: 'primary',
                  position: 'top-right',
                });
              }, error => {
                console.log(error);
              });
          }).catch(() => {

          });


      },


      alertuj(mytext) {
        if (mytext === 1) {
          alert(mytext + " - Benign");
        } else if (mytext === 2) {
          alert(mytext + " - Likely benign");
        } else if (mytext === 3) {
          alert(mytext + " - Uncertain significance");
        } else if (mytext === 4) {
          alert(mytext + " - Likely pathogenic");
        } else if (mytext === 5) {
          alert(mytext + " - Pathogenic");
        } else {
          alert(mytext);
        }
      },


      AC_genes(query) {
        if ( query.length > 2 ) {
          this.AC_genes_loading = true;

          axios.get(process.env.VUE_APP_BE + '/ac', { withCredentials: true, params: {'ac_type': 'l_gene', 'ac_data': query}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              this.$store.commit('m_l_gene', response.data["l_gene"]);
              this.AC_genes_loading = false;
            }, error => {
              console.log(error);
            });
        } else {

        }
      },


      click_hpo_terms() {
        this.m_modal_hpo_terms(true);
        this.a_hpo_get();

      },


      watch_id_examination (newid, oldid) {
        if (newid !== oldid) {
          if (this.igv_switch) {
            this.m_igv_switch(false);
          }
        }
      },


      watch_cufi_genes (newid, oldid) {
        if (newid && newid.length > 0) {
          console.log(newid.length);
        }
      },


      // watch_app (newval, oldval) {
      //   if (newval !== oldval) {
      //   }
      // },



    },

    beforeMount () {

    },


    created () {

    },


    mounted () {
      // if (this.rapp === 'fastras') {
      //   this.m_cufi_fastras();
      // } else {
      //   this.m_cufi_default();
      // }
    },


    afterDestroy () {

    },


    watch: {
      id_examination: {
        handler: 'watch_id_examination',
        immediate: true
      },

      // cufi_genes: {
      //   handler: 'watch_cufi_genes',
      //   immediate: true
      // },

      // app: {
      //   handler: 'watch_app',
      //   immediate: true
      // },

    },


  }
</script>
<style scoped lang="scss">
  body {
    margin: 0;
  }

  .btn {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .hranaty {
    border-radius: 2px !important;
  }

  .dolu {
    padding-top: 80px !important;
  }

  .mezera {
    margin-right: 10px !important;
  }

  .bottom_margin_30 {
    margin-bottom: 30px;
  }

  .bottom_margin_3 {
    margin-bottom: 3px;
  }

  .top_margin_3 {
    margin-top: 3px;
  }

  .top_margin_30 {
    margin-top: 30px;
  }

  .top_margin_50 {
    margin-top: 50px;
  }

  .bg_gray {
    background: #b7b9bb !important;
  }

  .bg_white {
    background-color: #FFF !important;
  }

  .gene_blue {
    background-color: #ccfdf3 !important;
    margin-left: 0px !important;
    border-radius: 6px !important;
  }

  .el-tag {
      border-radius: 2px !important;
      margin-left: 3px !important;
      margin-bottom: 3px !important;
  }

  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color:#FFF !important;
    background-color:#0e1a25 !important;
    border-color:#070e14 !important;
    -webkit-box-shadow:-1px 0 0 0 #8cc5ff !important;
    box-shadow:-1px 0 0 0 #8cc5ff !important;
  }

  .el-checkbox-button.is-checked:first-child .el-checkbox-button__inner {
    border-left-color:#0c151f !important;
  }

  .bg_white_tab {
    background-color: #FFF !important;
    z-index: 9999 !important;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  .bg-purple {
    background: #d3dce6;
  }

  .bg_blue {
    background-color: #ccfdf3 !important;
  }

  .bg-purple-light {
    background: #eee;
    min-height: 12px;
    padding-left: 4px !important;
  }

  .row-bg {
    padding: 3px 0;
    background-color: #f9fafc;
  }

  .min_height {
    border-radius: 4px;
    height: 30px !important;
  }

  .popover_bg {
    background: #a3bbf1;
  }

  .caret-wrapper {
    display: block !important;

  }

  .and_label {
      font-weight: 900 !important;
      font-size: 11px !important;
      color: rgb(117, 7, 7) !important;
  }

</style>
