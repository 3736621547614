<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <h6 class="title text-center">PATIENT CARD - ID: {{ patient_card.id_patient }} / {{ patient_card.surname }} {{ patient_card.name }}</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <el-tabs class="col-md-12" tab-position="left" style="min-height: 500px;" type="border-card" @tab-click="">
            <el-tab-pane label="Personal Data">
              <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="sub-title">Surname</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.surname">
                      </el-input>
                    </div>
                    <div class="col-md-4">
                      <div class="sub-title">Name</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.name">
                      </el-input>
                    </div>
                    <div class="col-md-3">
                      <div class="sub-title">Title</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.title">
                      </el-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3">
                      <div class="sub-title">PID</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.pid">
                      </el-input>
                    </div>
                    <div class="col-md-2">
                      <div class="sub-title">Birth Date</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder="YYYY-MM-DD"
                                v-model="patient_card.birth_date">
                      </el-input>
                    </div>
                    <div class="col-md-2">
                      <div class="sub-title">Age</div>
                      <el-input type="number"
                                label="Age"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="age">
                      </el-input>
                    </div>          
                    <div class="col-md-2">
                      <div class="sub-title">Gender</div>
                      <el-switch
                        style="display: block"
                        size="small"
                        v-model="patient_card.gender"
                        active-color="#777777"
                        inactive-color="#bbbbbb"
                        active-text="M"
                        inactive-text="W">
                      </el-switch>
                    </div>  
                    <div class="col-md-3">
                      <div class="sub-title">Ethnic</div>
                      <el-select class="select-neutral"
                                placeholder="Select Ethnic"
                                size="small"
                                v-model="patient_card.ethnic">
                        <el-option v-for="option in l_ethnic"
                                  class="select-neutral"
                                  :value="option.id_ethnic"
                                  :label="option.ethnic"
                                  :key="option.id_ethnic">
                        </el-option>
                      </el-select>
                    </div>                    
                  </div>

                  <div class="row">
                    <div class="col-md-2">
                      <div class="sub-title">Nationality</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.nationality">
                      </el-input>
                    </div>
                    <div class="col-md-2">
                      <div class="sub-title">Insurance Comp.</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.insurance">
                      </el-input>
                    </div>
                    <div class="col-md-3">
                      <div class="sub-title">Phone</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder="+420 123 456 789"
                                v-model="patient_card.phone">
                      </el-input>
                    </div>
                    <div class="col-md-5">
                      <div class="sub-title">Email</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder="@"
                                v-model="patient_card.email">
                      </el-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-5">
                      <div class="sub-title">Street</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.street">
                      </el-input>
                    </div>
                    <div class="col-md-3">
                      <div class="sub-title">City</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.city">
                      </el-input>
                    </div>
                    <div class="col-md-2">
                      <div class="sub-title">ZIP</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.zip">
                      </el-input>
                    </div>
                    <div class="col-md-2">
                      <div class="sub-title">Country</div>
                      <el-select class="select-neutral"
                                placeholder="Select Country"
                                size="small"
                                v-model="patient_card.country">
                        <el-option v-for="option in l_countries"
                                  class="select-neutral"
                                  :value="option.id_country"
                                  :label="option.country"
                                  :key="option.id_country">
                        </el-option>
                      </el-select>   
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="sub-title">Note</div>
                        <textarea rows="6" 
                            class="form-control border-input"
                            placeholder="Notes about Patient ..."
                            v-model="patient_card.note"
                            :disabled="patient_card_disabled"
                            >
                        </textarea>
                      </div>
                    </div>
                  </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Diagnoses">
                <el-tabs v-model="diagnoses_tabs" @tab-click="handleClick_diagnoses_tabs">
                  <el-tab-pane label="ICD11" name="d_1">
                    <div class="row">
                      <div class="col-4">
                              <el-input
                                v-model="searchQuery_icd11"
                                size=""
                                class="bottom_margin_10"
                                placeholder="Type to search ICD11" />
                      </div>

                      <div class="col-8">
                        <p-pagination class="pull-right"
                                      v-model="pagination_icd11.currentPage"
                                      :per-page="pagination_icd11.perPage"
                                      :total="pagination_icd11.total"
                                      :pager-count=5
                                      >
                        </p-pagination>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <el-table class=""
                                  ref="icd11"
                                  :data="icd11_data.filter(data => !searchQuery_icd11 || data.icd11_search.toLowerCase().includes(searchQuery_icd11.toLowerCase()))"
                                  border
                                  highlight-current-row
                                  @current-change="handle_add_icd11"
                                  style="width: 100%"
                                  size="medium"
                                  >
                          <el-table-column
                            prop="icd11_search"
                            label="ICD11 List"
                            min-width="180"
                            >
                            <template slot-scope="scope">
                              <span>{{ scope.row.icd11_search }}</span>
                            </template>
                          </el-table-column>                        
                        </el-table>
                      </div>

                      <div class="col-6">
                        <el-table class=""
                                  ref="icd11"
                                  :data="patient_card.icd11"
                                  border
                                  highlight-current-row
                                  @current-change="handle_del_icd11"
                                  style="width: 100%"
                                  size="medium"
                                  >
                          <el-table-column
                            prop="icd11_select"
                            label="ICD11 Selected"
                            min-width="180"
                            >
                            <template slot-scope="scope">
                              <span>{{ scope.row.icd11_search }}</span>
                            </template>
                          </el-table-column>                        
                        </el-table>
                      </div>

                      <div class="col-sm-4 pagination-info">
                        <p class="category">Showing {{from_icd11 + 1}} to {{to_icd11}} of {{pagination_icd11.total}} entries</p>
                      </div>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="Diagnoses Description" name="d_2">
                    <div class="row">
                      <div class="col-md-12 form-group">
                        <label>Diagnosis</label>
                        <textarea rows="12" 
                            class="form-control border-input"
                            placeholder=""
                            v-model="patient_card.diagnose1"
                            :disabled="patient_card_disabled"
                            >
                        </textarea>
                      </div>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="Privat Notes" name="d_3">
                    <div class="row">
                      <div class="col-md-12 form-group">
                        <label>Notes</label>
                        <textarea rows="12" 
                            class="form-control border-input"
                            placeholder=""
                            v-model="patient_card.privat_note"
                            :disabled="patient_card_disabled"
                            >
                        </textarea>
                      </div>
                    </div>                  

                  </el-tab-pane>

                </el-tabs>
            </el-tab-pane>

            <el-tab-pane label="HPO">
              <div class="row">
                  <div class="col-4">
                          <el-input
                            v-model="searchQuery_hpo"
                            size=""
                            class="bottom_margin_10"
                            placeholder="Type to search HPO" />
                  </div>

                  <div class="col-8">
                    <p-pagination class="pull-right"
                                  v-model="pagination_hpo.currentPage"
                                  :per-page="pagination_hpo.perPage"
                                  :total="pagination_hpo.total"
                                  :pager-count=5
                                  >
                    </p-pagination>
                  </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <el-table class=""
                            ref="hpo"
                            :data="hpo_data.filter(data => !searchQuery_hpo || data.hpo_term_name.toLowerCase().includes(searchQuery_hpo.toLowerCase()))"
                            border
                            highlight-current-row
                            @current-change="handle_add_hpo"
                            style="width: 100%"
                            size="medium"
                            >
                    <el-table-column
                      prop="hpo_term_name"
                      label="HPO List"
                      min-width="180"
                      >
                      <template slot-scope="scope">
                        <span>{{ scope.row.hpo_term_name }}</span>
                      </template>
                    </el-table-column>                        
                  </el-table>
                </div>

                <div class="col-6">
                  <el-table class=""
                            ref="hpo"
                            :data="patient_card.hpo"
                            border
                            highlight-current-row
                            @current-change="handle_del_hpo"
                            style="width: 100%"
                            size="medium"
                            >
                    <el-table-column
                      prop="hpo_term_name"
                      label="HPO Selected"
                      min-width="180"
                      >
                      <template slot-scope="scope">
                        <span>{{ scope.row.hpo_term_name }}</span>
                      </template>
                    </el-table-column>                        
                  </el-table>
                </div>

                <div class="col-sm-4 pagination-info">
                  <p class="category">Showing {{from_hpo + 1}} to {{to_hpo}} of {{pagination_hpo.total}} entries</p>
                </div>

              </div>
            </el-tab-pane>

            <el-tab-pane label="Examinations">
              <div class="col-md-12">
                <el-tabs v-model="examinations_tabs" @tab-click="handleClick_examinations_tabs">
                  <el-tab-pane label="NGS" name="e_1">
                    <div class="row">
                      <pcintexams></pcintexams>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="Others" name="e_2">
                    <div class="row">
                      <pcextexams></pcextexams>
                    </div>
                  </el-tab-pane>

                </el-tabs>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Family">
              <div class="col-md-12">
                <el-tabs v-model="family_tabs" @tab-click="handleClick_family_tabs">
                  <el-tab-pane label="Relations" name="f_1">
                    <div class="row">
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="Pedigree" name="f_2">
                    <div class="row">
                      <div class="col-md-12 form-group">
                        <label>Notes</label>
                        <textarea rows="12" 
                            class="form-control border-input"
                            placeholder=""
                            v-model="patient_card.privat_note"
                            :disabled="patient_card_disabled"
                            >
                        </textarea>
                      </div>
                    </div>                  
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Clin Data">
              <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="sub-title">Height (cm)</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.height">
                      </el-input>
                    </div>
                    <div class="col-md-4">
                      <div class="sub-title">Weight (kg)</div>
                      <el-input type="number"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.weight">
                      </el-input>
                    </div>
                    <div class="col-md-4">
                      <div class="sub-title">BMI</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.bmi">
                      </el-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="sub-title">Pressure</div>
                      <el-input type="text"
                                size="small"
                                :disabled="patient_card_disabled"
                                placeholder=""
                                v-model="patient_card.pid">
                      </el-input>
                    </div>
         
                    <div class="col-md-4">
                      <div class="sub-title">Smoker</div>
                      <el-switch
                        style="display: block"
                        size="small"
                        v-model="patient_card.smoker"
                        active-color="#777777"
                        inactive-color="#bbbbbb"
                        active-text="YES"
                        inactive-text="NO">
                      </el-switch>
                    </div>  

                    <div class="col-md-4">
                      <div class="sub-title">Drinker</div>
                      <el-switch
                        style="display: block"
                        size="small"
                        v-model="patient_card.drinker"
                        active-color="#777777"
                        inactive-color="#bbbbbb"
                        active-text="YES"
                        inactive-text="NO">
                      </el-switch>
                    </div>  
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="sub-title">Privat Note</div>
                        <textarea rows="8" 
                            class="form-control border-input"
                            placeholder=""
                            v-model="patient_card.privat_note"
                            :disabled="patient_card_disabled"
                            >
                        </textarea>
                      </div>
                    </div>
                  </div>
              </div>
            </el-tab-pane>

          </el-tabs>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <p-button native-type="button" slot="footer" type="info" block class="col-6 mb-3 ml-auto mr-auto" @click.native="save_patient_card"> Update Patient Data </p-button> 
        </div>
      </div>    
    </div>
  </div>
</template>
<script>
  import {Table, TableColumn, Select, Option, Tabs, TabPane, Tooltip, Progress, Switch, Input } from 'element-ui';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import PCIntExams from 'src/components/Views/PCIntExams.vue';
  import PCExtExams from 'src/components/Views/PCExtExams.vue';
  import Relations from 'src/components/Views/Relations.vue';

  import FileSaver from 'file-saver'; 
  import axios from 'axios';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    name: 'PatientCard',
    props: {
      got_id_patient: {
        type: String,
        default: '0',
        description: ''
      },
    },

    components: {
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Switch.name]: Switch,
      [Select.name]: Select,
      [Option.name]: Option,
      [Input.name]: Input,
      PPagination,
      [PCIntExams.name]: PCIntExams,
      [PCExtExams.name]: PCExtExams,
    },

    data () {
      return {
        age: 99,
        diagnoses_tabs: "d_1",
        examinations_tabs: "e_1",
        family_tabs: "f_1",
        history_tabs: "h_1",

        searchQuery_hpo: "",
        pagination_hpo: {
          perPage: 12,
          currentPage: 1,
          perPageOptions: [8,20],
          total: 0
        },

        searchQuery_icd11: "",
        pagination_icd11: {
          perPage: 8,
          currentPage: 1,
          perPageOptions: [8,20],
          total: 0
        },

        exam_pcr_files: [],
        searchQuery_pcr: "",
        pagination_pcr: {
          perPage: 8,
          currentPage: 1,
          perPageOptions: [8,20],
          total: 0
        },

      }
    },

    computed: {
      ...mapState({
        token: state => state.token,
        privat_be: state => state.privat_be,
        patient_card: state => state.patient_card,
        patient_card_disabled: state => state.patient_card_disabled,
        id_patient: state => state.id_patient,
        examinations_patient: state => state.examinations_patient,
        examinations_ext_patient: state => state.examinations_ext_patient,
        l_ethnic: state => state.l_ethnic,
        l_countries: state => state.l_countries,
        l_hpo: state => state.l_hpo,
        l_icd11: state => state.l_icd11,

      }),

    ...mapGetters({

      }),

      


      pagedData_hpo () {
        return this.l_hpo.slice(this.from_hpo, this.to_hpo)
      },

      hpo_data () {
        if (!this.searchQuery_hpo) {
          this.pagination_hpo.total = this.l_hpo.length
          return this.pagedData_hpo
        }
        let result = this.l_hpo
          .filter((row) => {
            let isIncluded = false
            let rowValue = row["hpo_term_name"].toString()
            if (rowValue.includes && rowValue.includes(this.searchQuery_hpo)) {
              isIncluded = true
            }
            return isIncluded
          })
        this.pagination_hpo.total = result.length
        return result.slice(this.from_hpo, this.to_hpo)
      },

      to_hpo () {
        let highBound = this.from_hpo + this.pagination_hpo.perPage
        if (this.total_hpo < highBound) {
          highBound = this.total_hpo
        }
        return highBound
      },

      from_hpo () {
        return this.pagination_hpo.perPage * (this.pagination_hpo.currentPage - 1)
      },

      total_hpo () {
        this.pagination_hpo.total = this.l_hpo.length
        return this.l_hpo.length
      },

      pagedData_icd11 () {
        return this.l_icd11.slice(this.from_icd11, this.to_icd11)
      },

      icd11_data () {
        if (!this.searchQuery_icd11) {
          this.pagination_icd11.total = this.l_icd11.length
          return this.pagedData_icd11
        }
        let result = this.l_icd11
          .filter((row) => {
            let isIncluded = false
            let rowValue = row["icd11_search"].toString()
            if (rowValue.includes && rowValue.includes(this.searchQuery_icd11)) {
              isIncluded = true
            }
            return isIncluded
          })
        this.pagination_icd11.total = result.length
        return result.slice(this.from_icd11, this.to_icd11)
      },

      to_icd11 () {
        let highBound = this.from_icd11 + this.pagination_icd11.perPage
        if (this.total_icd11 < highBound) {
          highBound = this.total_icd11
        }
        return highBound
      },

      from_icd11 () {
        return this.pagination_icd11.perPage * (this.pagination_icd11.currentPage - 1)
      },

      total_icd11 () {
        this.pagination_icd11.total = this.l_icd11.length
        return this.l_icd11.length
      },


    },


    methods: {
      save_patient_card () {
        this.a_patient_card();
      },

      test_me () {
        // event.stopImmediatePropagation();
        // axios.get(this.privat_be + '/test', { withCredentials: true, params: { "xxx": 123 }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        // axios.get(this.privat_be + '/test', { params: { "mycol": "examinations_ext", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
        axios.get(process.env.VUE_APP_BE + '/get_file', { withCredentials: true, params: { "mycol": "examinations_ext", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
                     var blob = new Blob([response.data]);
                     console.log(response);
                     FileSaver.saveAs(blob, response.config.params.filename);
            }, error => {
                console.log(error);
            });
      },

      make_report () {
        // event.stopImmediatePropagation();
        // axios.get(this.privat_be + '/test', { withCredentials: true, params: { "xxx": 123 }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        // axios.get(this.privat_be + '/test', { params: { "mycol": "examinations_ext", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
        axios.post(process.env.VUE_APP_BE + '/make_report', {}, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
                     var blob = new Blob([response.data]);
                     FileSaver.saveAs(blob, "report.pdf");
            }, error => {
                console.log(error);
            });
      },



      handleLike (index, row, event) {
        event.stopImmediatePropagation();
      },

      handleClick_diagnoses_tabs (tab, event) {
        event.stopImmediatePropagation();
        console.log(tab, event);
      },
      

      handleClick_examinations_tabs (tab, event) {
        event.stopImmediatePropagation();
        if (tab.name === "e_1") {
          this.a_examinations_patient();
        } else {
          this.a_examinations_ext_patient();
        }
        
        console.log(tab.name);
      },

      handleClick_family_tabs (tab, event) {
        event.stopImmediatePropagation();
        console.log(tab);
      },

      handleClick_history_tabs (tab, event) {
        event.stopImmediatePropagation();
        console.log(tab, event);
      },
      
      handle_add_hpo(val) {
        if (val) {
          let xxx = {hpo_id: val.hpo_id, hpo_term_name: val.hpo_term_name};
          this.m_patient_card_hpo_add(xxx);
          // let skuplek = [];
          // for (let a of this.cufi.genes.values()) {
          //   skuplek.push(a);
          // }
          // for (let s of val.genelist) {
          //   if (!(skuplek.includes(s))) {
          //     skuplek.push(s);
          //   }
          // }
          // skuplek.sort();
          // let mykey = "genes";
          // let value = skuplek;
          // this.$store.commit('m_cufi', {mykey, value});
        }
        // this.a_notify("HPO was added into Patient Data");

      },

      handle_del_hpo(val) {
        if (val) {
          this.m_patient_card_hpo_del(val.hpo_id); 
        }
      },

      handle_add_icd11(val) {
        if (val) {
          let xxx = {id_icd11: val.id_icd11, icd11_search: val.icd11_search};
          this.m_patient_card_icd11_add(xxx);
        }

      },

      handle_del_icd11(val) {
        if (val) {
          this.m_patient_card_icd11_del(val.id_icd11); 
        }
      },

      handleCurrentChange_pcr(val) {

      },

      handle_mongo_files(index, row, event, type) {
        event.stopImmediatePropagation();
        this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
      },


      ...mapMutations({
        m_id_patient: 'm_id_patient',
        m_id_examination: 'm_id_examination',
        m_id_project: 'm_id_project',
        m_vars: 'm_vars',
        m_patient_card_hpo_add: 'm_patient_card_hpo_add',
        m_patient_card_hpo_del: 'm_patient_card_hpo_del',
        m_patient_card_icd11_add: 'm_patient_card_icd11_add',
        m_patient_card_icd11_del: 'm_patient_card_icd11_del',        
      }),


      ...mapActions({
        a_patients: 'a_patients',
        a_patient_card: 'a_patient_card',
        a_patient_card_load: 'a_patient_card_load',
        a_patient_card_reset: 'a_patient_card_reset',

        a_examinations_patient: 'a_examinations_patient',
        a_examinations_ext_patient: 'a_examinations_ext_patient',
        a_notify: 'a_notify',
        a_mongo_files: 'a_mongo_files',

      }),

      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      },


    },


    watch: {
      id_patient: function () {
        if (this.id_patient > 0) {
          this.a_patient_card_load();
        } else {
          this.a_patient_card_reset();
        }
        // console.log(this.got_id_patient);
      },
    },



    beforeMount () {
      if (this.id_patient > 0) {
        this.a_patient_card_load();
      } else {
        this.a_patient_card_reset();
      }
    },

    mounted () {

    },


  }

</script>
<style scoped>
  .bottom_margin_30 {
    margin-bottom: 30px;
  }

  .bottom_margin_10 {
    margin-bottom: 10px;
  }

  .top_margin_10 {
    margin-top: 10px;
  }

  .top_margin_30 {
    margin-top: 30px;
  }

  .sub-title {
    font-size: 0.7em;
    margin-bottom: 1px;
    margin-top: 10px;
    color: black;
    text-transform: uppercase;
  }



</style>
